import { initializeApp } from "firebase/app";
import { isEmpty } from "lodash";
import placeholderImage from "../../assets/file-blank-solid-240.png";
import {
  getDatabase,
  ref,
  push,
  update,
  onValue,
  onChildChanged,
  off,
  remove,
  orderByKey,
  startAt,
  endAt,
  equalTo,
  query,
  limitToLast,
  runTransaction,
  orderByChild,
} from "firebase/database";
import {
  getAuth,
  EmailAuthProvider,
  OAuthProvider,
  GoogleAuthProvider,
  SAMLAuthProvider,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithPopup,
  isSignInWithEmailLink,
  fetchSignInMethodsForEmail,
  signInWithCustomToken,
  signInWithEmailLink,
  sendPasswordResetEmail,
  updatePassword,
  signOut,
  getIdToken,
  reload,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, list, deleteObject, uploadBytes, ref as storageRef, getDownloadURL } from "firebase/storage";

import * as Sentry from "@sentry/react";
import { VendorMicrosoft } from "@styled-icons/typicons/VendorMicrosoft";
import { Google } from "@styled-icons/boxicons-logos/Google";
import { LockOpen } from "@styled-icons/boxicons-solid/LockOpen";
import { getPublicUrl } from "./storage";

var config = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_REALTIME_DB,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
};

const fbPath = (...path) => {
  if (!path) throw new Error("Empty path");
  let joined = path.join("/");
  for (let p of path)
    if (p === null || p === undefined || p === "") throw new Error(`Empty component in Firebase path ${joined}`);
  return joined;
};

const sizeOf = obj => {
  if (!obj) return 0;
  // TODO apply overhead coefficient
  return JSON.stringify(obj).length;
};

class Firebase {
  constructor() {
    let fireApp = initializeApp(config);
    this.auth = getAuth(fireApp);
    this.db = getDatabase(fireApp);
    this.storage = getStorage(fireApp);
    this.functions = getFunctions(fireApp, import.meta.env.VITE_REGION);

    this.authProviders = {
      microsoft: {
        auth: new OAuthProvider("microsoft.com"),
        displayName: "Microsoft",
        icon: VendorMicrosoft,
        showInSignin: true,
      },
      google: {
        auth: new GoogleAuthProvider(),
        displayName: "Google",
        icon: Google,
        showInSignin: true,
      },
      "saml.jumpcloud-saml": {
        auth: new SAMLAuthProvider("saml.jumpcloud-saml"),
        displayName: "Jumpcloud",
        icon: LockOpen,
        showInSignin: false,
      },
      "saml.fictive-azure-test": {
        auth: new SAMLAuthProvider("saml.fictive-azure-test"),
        displayName: "Azure SAML Test",
        icon: LockOpen,
        showInSignin: false,
        logoutRedirect: true,
      },
      "saml.seb-azure-prod": {
        auth: new SAMLAuthProvider("saml.seb-azure-prod"),
        displayName: "SEB Active Directory",
        icon: LockOpen,
        emailMatch: new RegExp(/.*@seb\.se$/),
        showInSignin: false,
        logoutRedirect: true,
      },
      anon: {
        auth: null,
        displayName: "Anonymous",
        icon: LockOpen,
        emailMatch: null,
        showInSignin: false,
        logoutRedirect: true,
      },
    };

    // Prepare invokable cloud functions
    this.copyScenarioFiles = httpsCallable(this.functions, "assets-copyScenarioFiles");
    this.generateSpeech = httpsCallable(this.functions, "assets-generateSpeech");
    this.generateSpeechByPath = httpsCallable(this.functions, "assets-generateSpeechByPath");
    this.registerUser = httpsCallable(this.functions, "auth-registerUser");
    this.getUserDetails = httpsCallable(this.functions, "auth-getUserDetails");
    this.fetchSuggestedLines = httpsCallable(this.functions, "assets-fetchSuggestedLines");
    this.generatePin = httpsCallable(this.functions, "auth-generatePin");
    this.ensureSignedSessionUrls = httpsCallable(this.functions, "auth-ensureSignedSessionUrls");
    this.createInvite = httpsCallable(this.functions, "auth-createInvite");
  }

  // Cloud functions

  doGeneratePin = data => this.generatePin(data);

  doFetchSuggestedLines = data => this.fetchSuggestedLines(data);

  doCopyScenarioFiles = inputObj => this.copyScenarioFiles(inputObj);

  doGenerateSpeech = data => this.generateSpeech(data);

  doGenerateSpeechByPath = data => this.generateSpeechByPath(data);

  doRegisterUser = data => this.registerUser(data);

  doGetUserDetails = uid => this.getUserDetails({ uid });

  // Login user
  doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(this.auth, email, password);

  doSendEmailVerification = () => sendEmailVerification(this.auth.currentUser);

  doSendSignInLinkToEmail = (email, actionCodeSettings) => sendSignInLinkToEmail(this.auth, email, actionCodeSettings);

  doSignInAnonymously = () => signInAnonymously(this.auth);

  // // link auth provider to user account
  // doLinkWithPopUp = () => this.auth.currentUser.linkWithPopup(this.provider);

  doSignInWithProvider = provider =>
    this.authProviders[provider]
      ? signInWithPopup(this.auth, this.authProviders[provider].auth)
      : new Error(`Provider ${provider} not configured`);

  isSignInWithEmailLink = location => isSignInWithEmailLink(this.auth, location);

  isEmailPasswordProvider = provider => provider === EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD;
  isEmailLinkProvider = provider => provider === EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD;

  doFetchSignInMethodsForEmail = email => fetchSignInMethodsForEmail(this.auth, email);

  doSignInWithCustomToken = token => signInWithCustomToken(this.auth, token);

  doSignInWithEmailLink = (email, location) => signInWithEmailLink(this.auth, email, location);

  signOut = history => {
    window.localStorage.clear();
    if (!this.auth.currentUser) return history?.push({ pathname: "/signin", state: null });
    this.auth.currentUser.getIdTokenResult().then(res => {
      const provider = res.signInProvider;
      const nameId = this.auth.currentUser.email;
      if (provider && nameId && this.authProviders[provider]?.logoutRedirect) {
        signOut(this.auth).then(() =>
          window.location.replace(
            `/signout?redirect=true&idp=${encodeURIComponent(provider)}&nameId=${encodeURIComponent(nameId)}`
          )
        );
      } else {
        // By loading the URL from scratch, we will clear all Firebase listeners and get a clean state
        // Otherwise we would need to find and clean out all listeners to Firebase data that may depend on authUser to be permitted
        // This also ensures that the DataStore is reset, currently haven't found a clean way to do this by listening to authUser becoming null
        signOut(this.auth).then(() => window.location.replace("/signin"));
        // firebase.doSignOut(); // This doesn't create a visual reload of the page but has above issues
      }
    });
  };

  doPasswordReset = (email, actionCodeSettings) => sendPasswordResetEmail(this.auth, email, actionCodeSettings);

  doPasswordUpdate = password => updatePassword(this.auth.currentUser, password);

  // Other Auth

  authenticatedFetch = (url, options = {}) => {
    return this.getIdToken().then(token => {
      if (!options.headers) options.headers = {};
      options.headers["Authorization"] = `Bearer ${token}`;
      return fetch(url, options);
    });
  };

  getIdToken = () => getIdToken(this.auth.currentUser, false);

  refreshToken = () => getIdToken(this.auth.currentUser, true);

  reloadUser = () => reload(this.auth.currentUser);

  onAuthUserListener = (loginCb, logoutCb) =>
    this.auth.onAuthStateChanged(async authUser => {
      if (!authUser)
        // logged out
        return logoutCb();

      let claims = {};
      try {
        const idTokenResult = await authUser.getIdTokenResult();
        claims = idTokenResult?.claims || {};
      } catch (err) {
        console.error(err);
        return logoutCb();
      }

      let userId = authUser.uid;
      let dbUser = null;
      try {
        dbUser = await this.fetch(fbPath("users", userId));
      } catch (err) {
        // Ignore errors here as we have fallback strategy
      }

      // No user found in db
      if (!dbUser) {
        dbUser = {
          email: authUser.email,
          displayName: (authUser.email && authUser.email.match(/^([^@]*)@/)[1]) || "Anonymous",
          termsConsent: false,
        };
      }

      loginCb({
        ...dbUser,
        userId,
        claims,
        refreshToken: authUser.refreshToken,
      });
    });

  // General Storage

  putFile = (path, file, metadata) => uploadBytes(storageRef(this.storage, path), file, metadata);

  deleteFile = path => deleteObject(storageRef(this.storage, path));

  listFiles = path => list(storageRef(this.storage, path));

  /**
   * Creates sources from Google Storage paths for use with FileLibrary component
   *
   * @param {object} opts
   * @param {string} opts.path in Google Storage
   * @param {string} opts.name for the source
   * @param {string} opts.thumbnailSize - Size of thumbnail to use
   * @param {boolean} opts.upload - Whether to enable upload
   * @returns {object}
   */
  createStorageSource = ({ path, name, thumbnailSize = "320px", upload = false }) => {
    if (!path || !name) {
      console.error("Invalid parameters provided to createStorageSource");
      return null;
    }
    const source = {
      name,
      getFiles: async () => {
        try {
          const listResult = await this.listFiles(path);

          // Separate regular files from thumbnail files
          const regularFiles = [];
          const thumbnailMap = {};

          const thumbnailRegex = /\.resized\.(\d+px)\.webp$/;

          listResult.items.forEach(item => {
            const m = item.name.match(thumbnailRegex);
            if (m) {
              if (m[1] === thumbnailSize) {
                const originalName = item.name.replace(thumbnailRegex, "");

                // Store by original name for lookup
                if (!thumbnailMap[originalName]) {
                  thumbnailMap[originalName] = item;
                }
              }
            } else {
              regularFiles.push(item);
            }
          });

          const filePromises = regularFiles.map(async item => {
            let downloadUrl = getPublicUrl(item.fullPath);
            // We know that some paths are publicly accessible, so we can use them directly instead of making lots of API calls
            if (!downloadUrl) downloadUrl = await getDownloadURL(item);

            let thumbnailUrl = getPublicUrl(thumbnailMap[item.name]?.fullPath);
            if (!thumbnailUrl && thumbnailMap[item.name]) thumbnailUrl = await getDownloadURL(thumbnailMap[item.name]);

            // Use dedicated thumbnail if available, otherwise fallback to current logic
            thumbnailUrl =
              thumbnailUrl ||
              (item.name.match(/\.(jpg|jpeg|png|gif|webp)$/i) || item.fullPath.match(/\/media\//)
                ? downloadUrl
                : placeholderImage);

            // TODO we could return metadata but that requires a extra fetch with getMetadata
            return {
              id: item.fullPath,
              name: item.name,
              path: item.fullPath,
              thumbnail: thumbnailUrl,
              url: downloadUrl,
            };
          });

          return Promise.all(filePromises);
        } catch (error) {
          console.error(`Error fetching files from ${path}:`, error);
          return [];
        }
      },
    };
    if (upload) {
      source.uploadFile = (name, data, metadata) => this.putFile(fbPath(path, name), data, metadata);
    }
    return source;
  };

  // General DB

  /**
   * Fetches data (once) from Firebase Realtime Database while recording metrics to Sentry.
   * @param {string} path
   * @param {import("firebase/database").QueryConstraint[]} queries
   * @returns {Promise<object>}
   */
  instrumentedFetch = (path, queries) => {
    return Sentry.startSpan({ op: "db", name: `once: ${path}` }, async span => {
      let data = await this.fetch(path, queries);
      span.setAttribute("bytes", sizeOf(data));
      return data;
    });
  };

  /**
   * Fetches (once) data from Firebase Realtime Database.
   * @param {string} path
   * @param {import("firebase/database").QueryConstraint[]} queries
   * @returns {Promise<object>}
   */
  fetch = (path, queries) => {
    return new Promise((resolve, reject) => {
      let dbRef = ref(this.db, path);
      if (queries) {
        dbRef = query(dbRef, ...queries);
      }
      onValue(
        dbRef,
        snapshot => {
          resolve(snapshot.val());
        },
        {
          onlyOnce: true,
        },
        error => {
          reject(error);
        }
      );
    });
  };

  instrumentedListen = (path, callback, queries) => {
    if (!callback) return this.listen(path, null);
    let span = Sentry.startInactiveSpan({ op: "db", name: `on: ${path}` });
    let first = false;
    return this.listen(
      path,
      data => {
        // We only measure the span from start to first retun of data
        // NOTE that we might fail to end the span if we turn off the listen before it returns any
        if (!first) {
          first = true;
          span.end();
          // NOTE this will not instrument bytes correctly, because the callback will contain all
          // data at the location but the Websocket has only fetched the changed data
          span.setAttribute("bytes", sizeOf(data));
        }
        callback(data);
      },
      queries
    );
  };

  listen = (path, callback, queries) => {
    let dbRef = ref(this.db, path);
    if (queries) {
      dbRef = query(dbRef, ...queries);
    }
    if (callback) {
      return onValue(dbRef, snapshot => {
        callback(snapshot.val());
      });
    } else {
      // NOTE not always recommended as it clears all listeners on this ref (but not children)
      return off(dbRef);
    }
  };

  listenList = (path, callback, queries) => {
    let dbRef = ref(this.db, path);
    if (queries) {
      dbRef = query(dbRef, ...queries);
    }
    if (callback) {
      onChildChanged(dbRef, snapshot => {
        callback(snapshot.val());
      });
    } else {
      // NOTE not always recommended as it clears all listeners on this ref (but not children)
      off(dbRef);
    }
    return this.fetch(path, queries);
  };

  instrumentedUpdate = (path, value) =>
    Sentry.startSpan({ op: "db", name: `update: ${path}` }, span => this.update(path, value));

  update = (path, value) => {
    if (value === null) return remove(ref(this.db, path));
    else if (isEmpty(value)) throw new Error("Cannot update with an empty value except null (to delete)");
    else return update(ref(this.db, path), value);
  };

  // Scenario

  scenarioCoverImages = () => list(storageRef(this.storage, "thumbs/scenarioCoverImages"));

  scenarioSlideRef = (scenarioId, fileName) =>
    storageRef(this.storage, fbPath("scenarios", scenarioId, "slides", fileName));

  scenarioDocRef = (scenarioId, fileName) =>
    storageRef(this.storage, fbPath("scenarios", scenarioId, "docs", fileName));

  scenarioSlidePut = (scenarioId, fileName, file) => uploadBytes(this.scenarioSlideRef(scenarioId, fileName), file);

  scenarioSlideDelete = (scenarioId, fileName) => deleteObject(this.scenarioSlideRef(scenarioId, fileName));

  scenarioDocPut = (scenarioId, fileName, file) => uploadBytes(this.scenarioDocRef(scenarioId, fileName), file);

  scenarioDocDelete = (scenarioId, fileName) => deleteObject(this.scenarioDocRef(scenarioId, fileName));

  fetchAllScenarios = () => this.instrumentedFetch("/scenarios");

  fetchScenariosBetweenIds = ids => {
    if (!ids || !ids.length) return Promise.resolve({});
    return this.instrumentedFetch("/scenarios", [orderByKey(), startAt(ids[0]), endAt(ids[ids.length - 1])]);
  };

  fetchScenario = id => this.instrumentedFetch(fbPath("scenarios", id));

  updateScenario = (id, value) => this.update(fbPath("scenarios", id), value);

  pushNewSuggestion = suggestionObject => push(ref(this.db, fbPath("suggestions")), suggestionObject);

  updateSuggestions = updateObject => this.update(fbPath("suggestions"), updateObject);

  updateDocument = (scenarioId, docId, value) =>
    this.update(fbPath("scenarios", scenarioId, "documents", docId), value);

  pushNewDocument = (scenarioId, newDocument) =>
    push(ref(this.db, fbPath("scenarios", scenarioId, "documents")), newDocument);

  listenScenario = (id, callback) => this.listen(fbPath("scenarios", id), callback);

  updateScenarioModeExchange = (scenarioId, mode, exchangeId, value) =>
    this.update(fbPath("scenarios", scenarioId, "modes", mode, "exchanges", exchangeId), value);

  updateScenarioModeExchangeLine = (scenarioId, mode, exchangeId, lineId, value) =>
    this.update(fbPath("scenarios", scenarioId, "modes", mode, "exchanges", exchangeId, "lines", lineId), value);

  updateScenarioModeExchanges = (scenarioId, mode, value) =>
    this.update(fbPath("scenarios", scenarioId, "modes", mode, "exchanges"), value);

  // Sessions

  listenPersonalSessions = (userId, callback) =>
    this.instrumentedListen(fbPath("sessions"), callback, [orderByChild("playerId"), equalTo(userId), limitToLast(3)]);

  listenAllSessionsFromDate = (date, callback) =>
    this.instrumentedListen(fbPath("sessions"), callback, [orderByChild("created"), startAt(date), limitToLast(1000)]);

  stopListenSessions = () => this.listen("/sessions", null);

  listenSession = (sessionId, callback) => this.instrumentedListen(fbPath("sessions", sessionId), callback);

  updateSession = (sessionId, value) => this.update(fbPath("sessions", sessionId), value);

  updateSessionSharedWith = (sessionId, value) => this.update(fbPath("sessions", sessionId, "sharedWith"), value);

  updateSessionRating = (sessionId, evaluationId, sourceId, value) =>
    this.update(fbPath("sessions", sessionId, "evaluation", evaluationId, "ratings", sourceId), value);

  updateLineRating = (sessionId, lineId, evaluationId, sourceId, value) => {
    return this.update(
      fbPath("sessions", sessionId, "events", lineId, "evaluation", evaluationId, "ratings", sourceId),
      value
    );
  };

  addEventToSession = (sessionId, event) => {
    const appendSessionEventTransactor = eventToAdd => {
      return sessionEvents => {
        if (!sessionEvents) {
          return [eventToAdd];
        } else {
          return [...sessionEvents, eventToAdd];
        }
      };
    };
    const dbRef = ref(this.db, fbPath("sessions", sessionId, "events"));
    return runTransaction(dbRef, appendSessionEventTransactor(event));
  };

  // Organizations

  updateOrganizations = value => this.update("/organizations", value);

  fetchOrganizations = () => this.instrumentedFetch("/organizations");

  fetchOrganization = id => (id ? this.instrumentedFetch(fbPath("organizations", id)) : null);

  fetchOrganizationCoverImage = id => (id ? this.instrumentedFetch(fbPath("organizations", id, "coverImage")) : null);

  updateOrganization = (id, value) => this.update(fbPath("organizations", id), value);

  getSpecificOrganizations = orgIds => {
    if (!orgIds || !orgIds.length) throw new Error("No organization ids provided");
    return Sentry.startSpan({ op: "db", name: `once: <org>/${orgIds.join(", ")}` }, async span => {
      let promises = orgIds.map(id => this.fetch(fbPath("organizations", id)).then(org => ({ [id]: org })));
      let data = await Promise.all(promises).then(orgs => Object.assign({}, ...orgs));
      span.setAttribute("bytes", sizeOf(data));
      return data;
    });
  };

  // Users

  getUsersInOrganization = organizationId =>
    this.instrumentedFetch(fbPath("users"), [orderByChild("organization"), equalTo(organizationId)]);

  listenUser = (uid, callback) => this.instrumentedListen(fbPath("users", uid), callback);

  updateUser = (id, value) => this.update(fbPath("users", id), value);

  updateUserScenarioStats = (userId, scenarioId, value) =>
    this.update(fbPath("users", userId, "scenarioStats", scenarioId), value);

  // Voices, etc

  fetchAllVoices = () => this.instrumentedFetch("/voices");

  updateVoice = (id, value) => this.update(fbPath("voices", id), value);

  // Invites

  fetchInvite = id => this.fetch(fbPath("invites", id));

  deleteInvite = id => remove(ref(this.db, fbPath("invites", id)));

  doCreateInvite = value => this.createInvite(value).then(result => result.data);
}

export default Firebase;
