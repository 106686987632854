import * as ROUTES from "./routes";
import { Home } from "@styled-icons/fa-solid/Home";
import { TheaterMasks } from "@styled-icons/fa-solid/TheaterMasks";
import { ListUl } from "@styled-icons/fa-solid/ListUl";
import { SuperAdmin } from "../styles/icons";
import { HelpCircle } from "@styled-icons/boxicons-regular/HelpCircle";

export const navigationLinks = [
  {
    title: "Home",
    href: ROUTES.HOME,
    image: <Home />,
    adminOnly: false,
  },
  {
    title: "Scenarios",
    href: ROUTES.SCENARIOS,
    image: <TheaterMasks />,
    adminOnly: false,
  },
  {
    title: "Sessions",
    href: ROUTES.SESSIONS,
    image: <ListUl />,
    adminOnly: false,
  },
  {
    title: "Admin",
    href: ROUTES.ADMIN,
    image: <SuperAdmin />,
    adminOnly: true,
  },
  {
    title: "Create Scenario",
    href: ROUTES.SCENARIO_WIZARD,
    image: <TheaterMasks />,
    freemiumOnly: true,
  },
  {
    title: "Help",
    href: ROUTES.HELP,
    image: <HelpCircle />,
    adminOnly: false,
  },
];
