import EmptyAvatar from "../assets/emptyAvatar.webp";
import male from "../assets/avatars/male.png";
import female from "../assets/avatars/female.png";
import amandaFallingwater from "../assets/avatars/amandaFallingwater.png";
import carlCantaloupe from "../assets/avatars/carlCantaloupe.png";
import diegoLondon from "../assets/avatars/diegoLondon.png";
import everettBlues from "../assets/avatars/everettBlues.png";
import finleyFinn from "../assets/avatars/finleyFinn.png";
import noraYork from "../assets/avatars/noraYork.png";
import rileyReverb from "../assets/avatars/rileyReverb.png";
import sirGeoff from "../assets/avatars/sirGeoff.png";
import tatianaPlum from "../assets/avatars/tatianaPlum.png";

export const defaultAvatars = {
  EmployeeF: { name: "Standard Fiona", image: female, gender: "female", label: "standard" },
  TeacherM: { name: "Standard Mark", image: male, gender: "male", label: "standard" },
  "Riley Reverb": { name: "Riley Reverb", image: rileyReverb, gender: "other", label: "standard" },
  "Amanda Fallingwater": {
    name: "Amanda Fallingwater",
    image: amandaFallingwater,
    gender: "female",
    label: "standard",
  },
  "Carl Cantaloupe": { name: "Carl Cantaloupe", image: carlCantaloupe, gender: "male", label: "standard" },
  "Diego London": { name: "Diego London", image: diegoLondon, gender: "male", label: "standard" },
  "Everett Blues": { name: "Everett Blues", image: everettBlues, gender: "male", label: "standard" },
  "Finley Finn": { name: "Finley Finn", image: finleyFinn, gender: "male", label: "standard" },
  "Nora York": { name: "Nora York", image: noraYork, gender: "female", label: "standard" },
  "Sir Geoff": { name: "Sir Geoff", image: sirGeoff, gender: "male", label: "standard" },
  "Tatiana Plum": { name: "Tatiana Plum", image: tatianaPlum, gender: "female", label: "standard" },
  male: { name: "Standard Mark", image: male, gender: "male", label: "standard" },
  female: { name: "Standard Fiona", image: female, gender: "female", label: "standard" },
  other: { name: "Riley Reverb", image: rileyReverb, gender: "other", label: "standard" },
};

const rpmIdPattern = /([a-f0-9]{24})/;

export function getRPMAvatarPng(url, size) {
  // We may receive an old or new URL, pointing to a GLB or PNG.
  // First, extract the avatar ID from the URL.

  if (typeof url === "string") {
    let m = url.match(rpmIdPattern);
    if (m && m[1]) {
      let newUrl = `https://models.readyplayer.me/${m[1]}.png`;
      if (size) {
        newUrl += `?size=${size}`;
      }
      return newUrl;
    } else {
      return EmptyAvatar;
    }
  } else if (url && url.image) {
    return url.image;
  } else {
    return EmptyAvatar;
  }
}

export const getRPMAvatarImageSource = (avatarUrls, name) => {
  return {
    name,
    getFiles: async () => avatarUrls.map(url => ({ id: getRPMAvatarPng(url), thumbnail: getRPMAvatarPng(url, 320) })),
  };
};

export const getAvatarGender = avatarId => {
  return defaultAvatars[avatarId]?.gender || "other";
};

export const getAvatarImage = (avatarId, size) => {
  return defaultAvatars[avatarId]?.image || getRPMAvatarPng(avatarId, size);
};

/**
 *
 * @param {string} avatarId
 * @param {Array} avatars
 * @returns {string} The name of the avatar or the avatarId if not found
 */
export const getAvatarName = (avatarId, avatars) => {
  if (!avatarId) return "";
  if (avatarId in defaultAvatars) return defaultAvatars[avatarId]?.name;

  return avatars?.find(avatar => avatar.id === avatarId)?.name || avatarId;
};

export const isRPMAvatar = avatarId => {
  return !(avatarId in defaultAvatars);
};

export const onlyUniqueKnownAvatars = (knownAvatars, currentAvatarId) => {
  const seenIds = new Set();
  knownAvatars = knownAvatars || [];
  if (currentAvatarId) {
    knownAvatars = [...knownAvatars, { id: currentAvatarId, name: "Current Avatar" }];
  }

  return knownAvatars.filter(obj => {
    // Skips non-RPM avatars as we don't want them in the created avatars list
    if (!obj || !isRPMAvatar(obj.id) || seenIds.has(obj.id)) {
      return false; // discard object
    } else {
      seenIds.add(obj.id);
      return true; // keep object
    }
  });
};
