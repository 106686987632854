import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { smoothIn } from "../../styles/keyframes";
import { getCoverImage } from "../../components/Firebase/storage";
import { SCENARIO_EDITOR, ORG_SETTINGS, ADMIN } from "../../constants/routes";
import { isString } from "lodash";
import { AuthUserContext } from "../../components/Session";
import { DataStoreContext } from "../../components/DataStore/DataStoreContext";
import { checkAdmin } from "../../helpFunctions/auth";

const PageTitleWrapper = styled.div`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : "20px 0 20px 0")};
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  background: ${props => (props.bg ? `url(${props.bg})` : "initial")};
  background-position: right center;
  background-size: auto 64px;
  background-repeat: no-repeat;

  h1 {
    text-align: left;
    color: var(--grey);
    line-height: 39px;
    padding: 0;
    margin: 0;
  }

  h4 {
    text-align: left;
    color: var(--grey-2);
    line-height: 20px;
    span {
      color: var(--key-color-2);
    }
  }

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

export const PageTitle = ({ padding, title, secondTitle }) => {
  return (
    <PageTitleWrapper padding={padding}>
      <h1>{title}</h1>
      {secondTitle && <h4>{secondTitle}</h4>}
    </PageTitleWrapper>
  );
};

export const DetailedPageTitle = ({ title, padding, organization, userType }) => {
  return (
    <PageTitleWrapper padding={padding} bg={organization?.id && getCoverImage(organization.coverImage, "320px")}>
      <h1>{title}</h1>
      <>
        <h4>
          Organization:
          <span>{` ${organization?.displayName || organization?.id || (isString(organization) ? organization : "None")}`}</span>
        </h4>
        <h4>
          User Type:
          <span>{` ${userType || "None"}`}</span>
        </h4>
      </>
    </PageTitleWrapper>
  );
};

const AdminNavContainer = styled.ul`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px;
  position: relative;
  animation: ${smoothIn} 0.4s;
`;

const AdminNavLi = styled.li`
  margin: 0;
  padding: 5px 20px 5px 0px;
  a {
    color: var(--grey);
    border-bottom: ${props => (props.activeLink ? "3px solid var(--key-color-2)" : "3px solid transparent")};
    font-weight: bold;
    font-size: 18px;
    transition: 0.4s;
    // white-space: nowrap;
    &:hover {
      color: var(--grey);
      border-bottom: 3px solid var(--key-color-2);
      transition: 0.4s;
    }
  }
`;

const AdminNav = props => {
  const [activeLink, setActiveLink] = useState(ADMIN);
  const { authUser } = useContext(AuthUserContext);
  const { state } = useContext(DataStoreContext);
  const isAdmin = checkAdmin(authUser?.claims, state.selectedOrg);

  useEffect(() => {
    if (props.location.pathname) {
      setActiveLink(props.location.pathname);
    }
  }, [props.location.pathname]);

  return (
    <>
      {isAdmin && (
        <AdminNavContainer>
          <AdminNavLi activeLink={activeLink === ADMIN}>
            <Link to={ADMIN}>Overview</Link>
          </AdminNavLi>
          <AdminNavLi activeLink={activeLink.includes(SCENARIO_EDITOR)}>
            <Link to={SCENARIO_EDITOR}>Scenario Editor</Link>
          </AdminNavLi>
          <AdminNavLi activeLink={activeLink.includes(ORG_SETTINGS)}>
            <Link to={ORG_SETTINGS}>Organization Settings</Link>
          </AdminNavLi>
        </AdminNavContainer>
      )}
    </>
  );
};

export const AdminNavigation = withRouter(AdminNav);
