import styled from "styled-components";
import { smoothIn } from "../../styles/keyframes";

export const FileLibraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  background-color: var(--grey-6, #f5f5f5);
  border-radius: 4px;
  padding: 16px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-right: 4px;
`;

export const SourceSection = styled.section`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SourceHeader = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--grey-1, #333);
  padding: 8px 0;
  border-bottom: 1px solid var(--grey-4, #ddd);
  background-color: var(--grey-6, #f5f5f5);
  position: sticky;
  top: 0;
  z-index: 1;
  margin-top: 0;
  backdrop-filter: blur(4px);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UploadButton = styled.button`
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  background-color: var(--primary, #1890ff);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background-color: var(--primary-dark, #096dd9);
  }

  &:disabled {
    background-color: var(--grey-3, #d9d9d9);
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const HiddenFileInput = styled.input.attrs({ type: "file" })`
  display: none;
`;

export const FilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 12px;
  width: 100%;
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  background-color: ${props => (props.selected ? "var(--primary-light, #e6f7ff)" : "var(--grey-5, #f0f0f0)")};
  border: 2px solid ${props => (props.selected ? "var(--primary, #1890ff)" : "transparent")};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${props => (props.selected ? "var(--primary-light, #e6f7ff)" : "var(--grey-4, #e0e0e0)")};
  }
`;

export const SelectedFileItem = styled.div`
  max-width: ${props => (props.single ? "100%" : "100px")};
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const FileThumb = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
  margin-bottom: 8px;
`;

export const FileName = styled.span`
  font-size: 12px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  color: var(--grey-1, #333);
  min-height: 17px; /* Set minimum height for the filename */
`;

export const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  color: var(--grey-2, #666);
`;

export const DialogContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: rgb(102 102 102 / 70%);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation: ${smoothIn} 0.4s;
`;

export const SelectedFilesBox = styled.div`
  border: 1px solid var(--grey-4, #ddd);
  border-radius: 4px;
  padding: 6px;
  height: ${props => props.height || "auto"};
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: var(--grey-6, #f5f5f5);
  width: ${props => props.width || "fit-content"};
  max-width: 100%;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  align-items: center;
  overflow: hidden;

  &:hover {
    border-color: ${props => (props.disabled ? "var(--grey-4, #ddd)" : "var(--primary, #1890ff)")};
  }
`;

export const DialogContent = styled.div`
  position: relative;
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--grey-4, #ddd);
`;

export const DialogTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--grey-1, #333);
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--grey-3, #999);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;

  &:hover {
    background-color: var(--grey-5, #f0f0f0);
    color: var(--grey-1, #333);
  }
`;

export const DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid var(--grey-4, #ddd);
`;

export const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
  }
`;

export const CancelButton = styled(Button)`
  margin-right: 8px;
  border: 1px solid var(--grey-4, #ddd);
  background-color: white;
  color: var(--grey-1, #333);

  &:hover {
    background-color: var(--grey-5, #f0f0f0);
  }
`;

export const SaveButton = styled(Button)`
  border: none;
  background-color: var(--primary, #1890ff);
  color: white;

  &:hover {
    background-color: var(--primary-dark, #096dd9);
  }
`;

export const EmptySelectionMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--grey-3, #999);
  font-size: 14px;
`;
