import React, { useEffect, useContext } from "react";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import { DesktopNavWrapper, NavWrapper, StyledNavContainer } from "./style";
import SignOutButton from "../SignOut";
import { withRouter } from "react-router-dom";
import MobileNavigation from "./mobileNavigation";
import UserAvatarLink from "./components/UserAvatarLink";
import NavList from "./components/NavList";
import { DataStoreContext } from "../DataStore/DataStoreContext";
import FictiveLogo from "../../assets/fictive_logo_key_color.svg?url";
import { capitalizeFirstLetter } from "../../helpFunctions/session";

const ROUTES_WITHOUT_NAV = [ROUTES.SIGN_UP, ROUTES.SIGN_IN, ROUTES.OLD_PLAY_PAGE];

const Navigation = props => {
  const { state } = useContext(DataStoreContext);
  const { authUser } = useContext(AuthUserContext);

  const showNav = ROUTES_WITHOUT_NAV.find(route => props.location.pathname.startsWith(route)) === undefined;

  return !state.loading && showNav ? (
    <NavWrapper>
      <DesktopNavigation
        location={props.location.pathname}
        authUser={authUser}
        history={props.history}
        organization={state.organization}
      />
      <MobileNavigation authUser={authUser} history={props.history} />
    </NavWrapper>
  ) : null;
};

const DesktopNavigation = ({ location, authUser, history, organization }) => {
  useEffect(() => {
    if (location) {
      document.title = `${organization?.settings?.appName || "Fictive Reality"} - ${capitalizeFirstLetter(location.replace("/", ""))}`;
    }
  }, [location, organization]);

  const logoUrl = organization?.settings?.appLogo || FictiveLogo;

  const handleClick = href => {
    history.push(href);
  };

  return (
    <DesktopNavWrapper>
      <StyledNavContainer>
        <img src={logoUrl} alt="App logo" />
        <NavList
          showTooltip
          handleClick={handleClick}
          authUser={authUser}
          currentPath={location}
          organization={organization}
        />
        {authUser && <UserAvatarLink authUser={authUser} showTooltip />}
        {authUser && <SignOutButton history={history} showTooltip />}
      </StyledNavContainer>
    </DesktopNavWrapper>
  );
};

export default withRouter(Navigation);
