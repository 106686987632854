/* eslint-disable react-hooks/rules-of-hooks */
import { produce, original, isDraft } from "immer";
import { get as _get, set as _set, isArray, isEmpty, isEqual, isNaN, isNil, isObject, merge, unset } from "lodash";
import React, { createContext, useContext, useState } from "react";
import { createStore, useStore } from "zustand";
import { DataStoreContext } from "../../components/DataStore/DataStoreContext";
import { isDebugMode } from "../../helpFunctions/general";
import { JsonView, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

/*
EXPLANATION
This code, and all it depends on, has gone through many iterations and lots of work, so it's best to understand all implications before changing it.

In short, what FormProvider solves is to be a shared state and context for a set of forms editing the same type of object, e.g. a scenario or a user.

REQUIREMENTS
- We want to be able to create multiple variations of forms, or multi-page forms, connecting to the same state.
- We want to be able to track both local form data and saved form data (e.g. from backend). Updates on the server shouldn't override or interfere with locla changes.
No need yet for advanced conflict resolution, we just keep local changes which when saved overrides what's on server.
- We want input field components to not have direct dependencies to a specific form instance, and instead be reusable in multiple form contexts.
- We want the simplicity of almost a one-liner to conenct e.g. a text input to the form, while still supporting larger form components that edit a whole sub-object.
- We need to include validation rules centrally, so that all forms that edit the same object have the same rules. The validation is done through JSON schema with some helpers.
- We want to avoid re-rendering the whole form when a single field changes, as otherwise performance or possible bugs could be a problem.

SOLUTION
There are two major elements of the solution that relates to how React works:

1) To make form fields connect to a form without passing props or being coded to know about a specific form, we use React Context. This becomes a form of dependency injection. 
useFormField("field.name") makes the component look for the closest parent form context to retrieve it's state, and the field.name part ensures this component only listens
to changes relating to that specific form field.

2) If we just dumped all form state into a single context, every change would cause a re-render of all form fields. To avoid this, we use Zustand, a state management library that
makes the object store itself not trigger re-renders, but instead we can create hooks that let's us react to specific parts of the store.

3) This means that each type of object we want to edit with a form needs it's own Zustand store and therefore it's own <Something>FormProvider, which is a parent component that stores the
state in an instance of a FormContext. Because there is just one type of context, it means all form fields can connect to it without knowing about the specific form instance. It also means
that any special logic we want for loading and saving data for this object goes into the <Something>FormProvider, not in the common FormProvider code.

4) Validation is done through a home-brewed JSON schema validation function that can recursively validate a whole object based on a JSON schema. If there isn't an existing JSON schema
available, one can be done with simpler rules through a utility function.

GOTCHAS
This is far from a perfect solution and the underlying problem is complex either way you cut it. Here are some things to be aware of:
- With nested objects, nothing stops you from connecting one field to a child property `x.y.z` and another to the parent `x.y`. There is some logic to keep the change tracking consistent even if this
happens but it's a complex topic and there are many edge cases that probably aren't covered, such as error handling and validation.
- The internal state of the form object is exposed in the store and there is not much IDE or type support which means you need to be aware of the structure. E.g. if you want to listen
to changes in local changes to `settings`, you listen to state => state.formData.settings, which is different than state.savedData.settings, and you can't just listen to state.settings in above example.

TODO
- Undo/redo using immer patches functionality
- Set more fields to validate onBlur, or, set a form-global onBlur validation. Need to only validate the currently editing fields.
- Break out as library
*/

const eq = (a, b) => {
  if (isNil(a) && isNil(b)) {
    return true; // both are null/undefined, so they are equal
  }
  return isEqual(a, b); // regular comparison for other values
};

/**
 * @typedef {Object} ValidationError
 * @readonly
 * @enum {string}
 * @property {string} REQUIRED - Field is required
 * @property {string} TYPE_MISMATCH - Value is wrong type
 * @property {string} MIN_LENGTH - Value is too short
 * @property {string} MAX_LENGTH - Value is too long
 * @property {string} MINIMUM - Value is below minimum
 * @property {string} MAXIMUM - Value is above maximum
 * @property {string} ENUM_MISMATCH - Value is not in enum
 * @property {string} PATTERN_MISMATCH - Value doesn't match pattern
 * @property {string} CUSTOM - Custom validation error
 */

/** @type {ValidationError} */
export const ValidationErrors = Object.freeze({
  REQUIRED: v => ({ id: "REQUIRED", v }),
  TYPE_MISMATCH: v => ({ id: "TYPE_MISMATCH", v }),
  MIN_LENGTH: v => ({ id: "MIN_LENGTH", v }),
  MAX_LENGTH: v => ({ id: "MAX_LENGTH", v }),
  MINIMUM: v => ({ id: "MINIMUM", v }),
  MAXIMUM: v => ({ id: "MAXIMUM", v }),
  ENUM_MISMATCH: v => ({ id: "ENUM_MISMATCH", v }),
  PATTERN_MISMATCH: v => ({ id: "PATTERN_MISMATCH", v }),
  FORMAT_MISMATCH: v => ({ id: "FORMAT_MISMATCH", v }),
  CUSTOM: v => ({ id: "CUSTOM", v }),
});

const errorTranslations = {
  REQUIRED: "This field is required",
  TYPE_MISMATCH: "Must be of type %v",
  MIN_LENGTH: "Must be at least %v characters",
  MAX_LENGTH: "Must be less than %v characters",
  MINIMUM: "Must be more than %v",
  MAXIMUM: "Must be less than %v",
  ENUM_MISMATCH: "Value is not in allowed list",
  PATTERN_MISMATCH: "Value doesn't match allowed pattern",
  FORMAT_MISMATCH: "Value doesn't match format %v",
  CUSTOM: "Custom validation error",
};

export const renderValidationError = errorObj =>
  errorObj ? errorTranslations?.[errorObj.id].replace("%v", errorObj.v) : "";

/**
 * Determines if a path should be validated based on includePaths and excludePaths
 * @param {string[]} includePaths - Paths that should be validated (if empty, all paths are included)
 * @param {string[]} excludePaths - Paths that should be skipped (if empty, no paths are excluded)
 * @param {string} path - Current path to check
 * @returns {boolean} - Whether the path should be validated
 */
const shouldValidatePath = (includePaths, excludePaths, path) => {
  if (!path) return true; // No path always matches
  // If path is explicitly excluded, skip it
  if (Array.isArray(excludePaths) && excludePaths.some(p => path === p || path.startsWith(p + "."))) {
    return false;
  }

  // If includePaths is empty, include all non-excluded paths
  if (!Array.isArray(includePaths) || includePaths.length === 0) {
    return true;
  }

  // Only validate paths that match or are parents/children of includePaths
  return includePaths.some(p => path === p || path.startsWith(p + ".") || p.startsWith(path + "."));
};

/**
 * Validates a data structure based on a JSON schema. It will recursively validate all sub-objects and
 * add all results to an error map with the path of the validated property as key. It doesn't require a full
 * JSON schema, it will traverse as long as there is a properties or additionalProperties object.
 * Note that it cannot lookup references in a schema.
 * @param {*} value an object or single value to validate
 * @param {object} schema JSON schema compatible object, need to have the same structure at root as the value
 * @param {string[]} includePaths paths that should be validated (if empty, all paths are validated)
 * @param {string[]} excludePaths paths that should be skipped (if empty, no paths are excluded)
 * @param {string} path the current path in the object (for recursive calls)
 * @param {object} errors the current error object (for recursive calls)
 * @returns {object} an object with paths as keys and error identifier strings as values
 */
export const validateFromSchema = (value, schema, includePaths = null, excludePaths = null, path = "", errors = {}) => {
  // Skip validation if this path should not be validated
  if (!shouldValidatePath(includePaths, excludePaths, path)) {
    return errors;
  }

  if (!schema) {
    return errors;
  }

  if (schema.required) {
    // Special handling for boolean type - only null/undefined are invalid
    if (schema.type === "boolean") {
      if (value === undefined || value === null) {
        return { ...errors, [path]: ValidationErrors.REQUIRED() };
      }
    } else {
      if (
        value === undefined ||
        value === null ||
        value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        (isObject(value) && Object.keys(value).length === 0)
      ) {
        return { ...errors, [path]: ValidationErrors.REQUIRED() };
      }
    }
  }

  // Allow null if not required, e.g. we don't treat null as a type mismatch
  if (!schema.required && (value === undefined || value === null)) {
    return errors;
  }

  if (schema.properties) {
    for (let [key, subSchema] of Object.entries(schema.properties)) {
      errors = validateFromSchema(
        _get(value, key),
        subSchema,
        includePaths,
        excludePaths,
        path ? `${path}.${key}` : key,
        errors
      );
    }
    return errors;
  } else if (schema.additionalProperties) {
    for (let [key, val] of Object.entries(value || {})) {
      errors = validateFromSchema(
        val,
        schema.additionalProperties,
        includePaths,
        excludePaths,
        path ? `${path}.${key}` : key,
        errors
      );
    }
    return errors;
  } else {
    // Allow null if not required, e.g. we don't treat null as a type mismatch
    if (!schema.required && (value === undefined || value === null)) {
      return errors;
    }

    switch (schema.type) {
      case "string":
        if (typeof value !== "string") {
          return { ...errors, [path]: ValidationErrors.TYPE_MISMATCH("string") };
        }
        if (!isNil(schema.minLength) && value.length < schema.minLength) {
          return { ...errors, [path]: ValidationErrors.MIN_LENGTH(schema.minLength) };
        }
        if (!isNil(schema.maxLength) && value.length > schema.maxLength) {
          return { ...errors, [path]: ValidationErrors.MAX_LENGTH(schema.maxLength) };
        }
        if (schema.pattern && !new RegExp(schema.pattern).test(value)) {
          return { ...errors, [path]: ValidationErrors.PATTERN_MISMATCH(schema.pattern) };
        }
        if (schema.format) {
          switch (schema.format) {
            case "email":
              if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                return { ...errors, [path]: ValidationErrors.FORMAT_MISMATCH(schema.format) };
              }
              break;
            case "date":
              if (isNaN(Date.parse(value))) {
                return { ...errors, [path]: ValidationErrors.FORMAT_MISMATCH(schema.format) };
              }
              break;
            default:
              break;
          }
        }
        return errors;
      case "number":
      case "integer":
        if (typeof value !== "number") {
          return { ...errors, [path]: ValidationErrors.TYPE_MISMATCH("number") };
        }
        if (!isNil(schema.minimum) && value < schema.minimum) {
          return { ...errors, [path]: ValidationErrors.MINIMUM(schema.minimum) };
        }
        if (!isNil(schema.maximum) && value > schema.maximum) {
          return { ...errors, [path]: ValidationErrors.MAXIMUM(schema.maximum) };
        }
        return errors;
      case "boolean":
        if (typeof value !== "boolean") {
          return { ...errors, [path]: ValidationErrors.TYPE_MISMATCH("boolean") };
        }
        return errors;
      case "enum":
        if (!schema?.enum?.includes(value)) {
          return { ...errors, [path]: ValidationErrors.ENUM_MISMATCH() };
        }
        return errors;
      default:
        return errors;
    }
  }
};

export const applyUpdateToForm = (formState, updateObject) => {
  if (!isObject(updateObject))
    throw new Error("applyUpdateToForm expects an object with key-value pairs to update the form with.");
  for (let [key, value] of Object.entries(updateObject ?? {})) {
    const formKey = key.replaceAll("/", ".");
    formState.setValue(formKey, value);
  }
};

/**
 * Utility function to expand a short validation schema to a full JSON schema object, so we can
 * use it with jsonSchemaValidate.
 * @param {*} shortValidationSchema
 * @returns
 */
export const expandToSchema = shortValidationSchema => {
  const schema = {};
  for (let [key, value] of Object.entries(shortValidationSchema)) {
    const schemaPath = key.split(".").reduce((acc, part) => {
      // TODO currently won't handle arrays, just assume * means additionalProperties (e.g. object)
      if (part === "*") {
        acc.push("additionalProperties");
      } else {
        acc.push("properties");
        acc.push(part);
      }
      return acc;
    }, []);

    // Join path parts and set value
    const current = _get(schema, schemaPath);
    const merged = merge(current, value);
    _set(schema, schemaPath, merged);
    // TODO to make the schema complete, we'd need to add type: "object" to all parent objects
  }
  return schema;
};

/**
 * This method is called by setValue to efficiently maintain a changes object, which also
 * looks and behaves like a Firebase update object, e.g. first level keys are keypaths, and values are the updated values.
 * @param {object} baseState the state object before setting the value
 * @param {string} updatedField the field that was updated.
 * @param {*} newValue the new value that was set. Null means the value was unset.
 * @return {object} the new state object
 */
export const doSetValue = (baseState, field, value) => {
  if (!field) throw new Error("No field identifier passed to updateChanges.");
  return produce(baseState, draft => {
    // Check if value is an event object and extract the actual value
    if (draft.isDisabled) return;
    if (value && value.target) {
      if (value.target.type === "checkbox") {
        value = value.target.checked;
      } else if (value.target.type === "number") {
        value = value.target.valueAsNumber;
      } else {
        value = value.target.value;
      }
    } else if (value && value.value) {
      // The result of a CustomSelect
      value = value.value;
    }

    if (isNil(value)) {
      // Setting to nil means we want to delete it
      unset(draft.formData, field);
    } else {
      _set(draft.formData, field, value);
    }

    // Current form data is different than the saved data
    // We need to find if there is already a change at a parent level and use that instead
    let pos = field.indexOf(".");
    let parentPath;
    let changed = !eq(value, _get(draft.savedData, field));

    while (pos !== -1) {
      parentPath = field.substring(0, pos);
      if (parentPath in draft.changes) {
        break;
      } else {
        parentPath = null;
      }
      pos = field.indexOf(".", pos + 1);
    }

    if (parentPath) {
      // an existing change in parent found, edit that instead
      const subFieldPath = field.substring(pos + 1);
      if (!isObject(draft.changes[parentPath])) {
        draft.changes[parentPath] = {};
      }
      if (changed) {
        _set(draft.changes[parentPath], subFieldPath, value ?? null);
      } else {
        unset(draft.changes[parentPath], subFieldPath);
      }
      if (isEmpty(draft.changes[parentPath])) {
        delete draft.changes[parentPath];
      }
    } else {
      // no parent changes found, just set it at the normal level
      if (changed) {
        draft.changes[field] = value ?? null; // ensures we set null if value is undefined
      } else {
        delete draft.changes[field];
      }
    }

    // also, if there any changes to children to this field, this change supersedes them
    Object.keys(draft.changes || {})
      .filter(k => k.startsWith(field + "."))
      .forEach(p => delete draft.changes[p]);

    // If there are any errors on this field or children, we clear it as we don't know if the new value is valid
    Object.keys(draft.errors || {})
      .filter(k => k.startsWith(field))
      .forEach(p => delete draft.errors[p]);

    draft.isDirty = !isEmpty(draft.changes);
  });
};

/**
 * If we receive new savedData, we want to keep any changes made by the user but update rest of formData to the latest
 * savedData. Therefore
 * Note that this is not a simple diff. If a field has changed or been added in savedData, it will create a diff to formData
 * but we don't want to note it as a change, because it's not a user change, and it may relate to other parts of the object
 * that aren't editable by the user at this stage.
 * @param {object} baseState
 * @return {object} the new state object
 */
export const doSetSavedData = (baseState, newSavedData) => {
  return produce(baseState, draft => {
    // Overwrite all data not changed by user with saved version
    draft.formData = newSavedData ?? {};
    const newErrors = {};
    for (let [field, value] of Object.entries(draft.changes)) {
      // Apply the changes onto the newSavedData to create the formData
      if (!eq(value, _get(newSavedData ?? {}, field))) {
        // A user change is different from the new saved data, keep the change
        if (isNil(value)) {
          unset(draft.formData, field);
        } else {
          _set(draft.formData, field, value);
        }
        // Only keep errors for fields that are changed by user
        if (draft.errors[field]) newErrors[field] = draft.errors[field];
      } else {
        delete draft.changes[field];
      }
    }
    draft.savedData = newSavedData; // null is allowed
    draft.errors = newErrors;
    draft.isDirty = !isEmpty(draft.changes);
    draft.isValid = isEmpty(draft.errors);
  });
};

/**
 * Validates the state.formData object
 * @param {object} baseState
 * @param {string[]} includePaths paths that should be validated (if empty, all paths are validated)
 * @param {string[]} excludePaths paths that should be skipped (if empty, no paths are excluded)
 * @returns {object} the new state object
 */
export const doValidate = (baseState, validationSchema, includePaths = null, excludePaths = null) => {
  const errors = validateFromSchema(baseState.formData, validationSchema, includePaths, excludePaths);
  return produce(baseState, draft => {
    draft.errors = errors;
    draft.isValid = isEmpty(errors);
  });
};

/**
 * Updates the suggestions for a specific field in the form state.
 * @param {Object} baseState - The current state object
 * @param {string} field - Field path to update suggestions for
 * @param {Object} suggestions - Suggestion object with id, current position and options array
 * @returns {Object} New state with updated suggestions
 */
export const doSetSuggestions = (baseState, field, suggestions) => {
  return produce(baseState, draft => {
    if (!draft.suggestions) {
      draft.suggestions = {};
    }

    if (suggestions === null || suggestions === undefined) {
      delete draft.suggestions[field];
    } else {
      // Ensure the suggestion object has the expected shape
      draft.suggestions[field] = {
        id: suggestions.id,
        current:
          suggestions.current > -1 && suggestions.current < suggestions.suggestions?.length ? suggestions.current : -1,
        suggestions: Array.isArray(suggestions.suggestions) ? suggestions.suggestions : [],
      };
    }
  });
};

/**
 * This function should run just before saving the data and clearing the changes.
 * It looks at each change done, and sees if there is a corresponding suggestion object, `s`.
 * If there is, and `s.current` is > -1, remove that index from `s.suggestions` array, then set `s.current` to -1.
 * It should return a object with keypaths that had a suggestion, and the removed suggestion.
 * It should use immer produce for the update state without the matching suggestions.
 * @param {*} baseState
 */
export const doConsolidateSuggestions = (baseState, appliedSuggestions) => {
  return produce(baseState, draft => {
    for (let [field, suggestionObj] of Object.entries(draft.suggestions || {})) {
      // Only process suggestions that have been selected (current > -1)
      if (suggestionObj.current > -1) {
        const selectedIndex = suggestionObj.current;
        const appliedValue = _get(draft.formData, field) ?? null;

        // Check if the current value matches the suggested value
        // Record this suggestion as applied
        appliedSuggestions[field] = {
          suggestionId: suggestionObj.id,
          appliedValue: isDraft(appliedValue) ? original(appliedValue) : appliedValue,
        };

        if (suggestionObj.suggestions.length > selectedIndex) {
          // Remove the applied suggestion from the list
          suggestionObj.suggestions = [
            ...suggestionObj.suggestions.slice(0, selectedIndex),
            ...suggestionObj.suggestions.slice(selectedIndex + 1),
          ];
        }

        // Reset current to -1 since the suggestion has been applied
        suggestionObj.current = -1;

        // If no suggestions left, remove the entire entry
        if (suggestionObj.suggestions.length === 0) {
          delete draft.suggestions[field];
        }
      }
    }
  });
};

/**
 * A general context for a form that contains a Zustand store.
 */
export const FormContext = createContext({});

export const createFormStore = (defaultValues, validationSchema) => {
  return createStore(set => ({
    id: null,
    formData: defaultValues ?? {},
    savedData: null,
    errors: {},
    changes: {},
    suggestions: {},
    isValid: true,
    isDirty: false,
    isDisabled: false,
    setIsDisabled: value => set({ isDisabled: value }),
    setId: id => set({ id }),
    validate: (options = {}) => {
      // Need to mess around with these two variables a bit to be able to return them from validate
      const { includePaths = null, excludePaths = null } = options;
      let isValid = true;
      let errors = {};
      set(state => {
        const newState = doValidate(state, validationSchema, includePaths, excludePaths);
        isValid = newState.isValid;
        errors = newState.errors;
        return newState;
      });
      return { isValid, errors };
    },
    setValue: (field, value) => set(state => doSetValue(state, field, value)),
    reset: () =>
      set(state => ({
        formData: state.savedData ?? defaultValues ?? {},
        errors: {},
        changes: {},
        suggestions: {},
        isValid: true,
        isDirty: false,
      })),
    setSavedData: data => set(state => doSetSavedData(state, data)),
    setSuggestions: (field, suggestions) => set(state => doSetSuggestions(state, field, suggestions)),
    consolidateSuggestions: () => {
      const appliedSuggestions = {};
      set(state => doConsolidateSuggestions(state, appliedSuggestions));
      return appliedSuggestions;
    },
  }));
};

export const useForm = selector => {
  const { store } = useContext(FormContext);
  if (store) {
    // breaks the rule of no hooks being conditional, but should still work?
    return useStore(store, selector);
  } else {
    return {};
  }
};

export const useFormField = field => {
  if (!field) throw new Error("No field identifier passed to useFormField.");
  let { store } = useContext(FormContext);
  if (!store) return {}; // breaks the rule of hooks being conditional, but because no hooks are run in this case it should still work?
  const value = useStore(store, state => _get(state.formData, field));
  const savedValue = useStore(store, state => _get(state.savedData, field));
  const setValue = useStore(store, state => state.setValue);
  const setAllSuggestions = useStore(store, state => state.setSuggestions);
  const validateAllPaths = useStore(store, state => state.validate);
  // Tells us to validate just the specific field
  const validate = () => validateAllPaths({ includePaths: [field] });
  const suggestionsObj = useStore(store, state => _get(state.suggestions, field));
  const isDisabled = useStore(store, state => state.isDisabled);
  const change = useStore(store, state => _get(state.changes, field));
  const isChanged = change !== undefined; // null means a deletion e.g. change has happened
  const showsSuggestion = suggestionsObj?.current > -1 && suggestionsObj?.current < suggestionsObj?.suggestions?.length;
  const onChange = newValue => setValue(field, newValue);
  const setSuggestions = suggestions => setAllSuggestions(field, suggestions);
  const errorObj = useStore(store, state => state.errors[field]);
  const error = renderValidationError(errorObj);
  return {
    formId: field,
    value,
    savedValue,
    onChange,
    isChanged,
    error,
    "aria-invalid": !!error,
    "aria-errormessage": error,
    validate,
    suggestions: suggestionsObj,
    setSuggestions,
    showsSuggestion,
    disabled: isDisabled,
    props: {
      value: value || "",
      onChange,
      error,
      "aria-invalid": !!error,
      "aria-errormessage": error,
      disabled: isDisabled,
    },
  };
};

export const DebugScenarioForm = () => {
  const formStore = useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { state } = useContext(DataStoreContext);
  const shouldBeExpanded = level => !isCollapsed || level == 0;

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return isDebugMode(state) ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
        maxHeight: "100vh",
        overflow: "auto",
        maxWidth: "50vw",
        fontFamily: "monospace",
      }}
    >
      <div
        style={{
          position: "sticky",
          zIndex: 1001,
          display: "flex",
          gap: "10px",
        }}
      >
        <button onClick={toggleVisibility}>{isVisible ? "Hide Form Data" : "Show Form Data"}</button>
        {isVisible && <button onClick={() => formStore.reset()}>Reset</button>}
        {isVisible && (
          <button onClick={() => formStore.setIsDisabled(!formStore.isDisabled)}>
            {formStore.isDisabled ? "Enable" : "Disable"}
          </button>
        )}
        {isVisible && (
          <button onClick={() => setIsCollapsed(!isCollapsed)}>{isCollapsed ? "Expand" : "Collapse"}</button>
        )}
      </div>
      {isVisible && (
        <div
          style={{
            maxHeight: "calc(100vh - 10px)",
            overflow: "auto",
            border: "1px dashed",
          }}
        >
          <JsonView
            data={formStore}
            shouldExpandNode={shouldBeExpanded}
            style={{ ...defaultStyles, container: "json-view-container" }}
          />
        </div>
      )}
    </div>
  ) : null;
};
