import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Spinning, ButtonPulse, FadeIn } from "../../styles/keyframes";
import { getCoverImage } from "../../components/Firebase/storage";
import maskGroupImg from "../../assets/mask-group.png";
import { Checkbox } from "@styled-icons/boxicons-regular/Checkbox";
import { CheckboxChecked } from "@styled-icons/boxicons-solid/CheckboxChecked";
import { smoothIn } from "../../styles/keyframes";

export const FrCheckboxBtn = styled.button`
  background: transparent;
  padding: none;
  margin: none;
  outline: none;
  border: 2px solid transparent;
  box-shadow: 0px 0px 0px 2px transparent;
  transition: 0.4s;
  border-radius: 5px;

  svg {
    transition: 0.4s;
    width: ${props => (props.width ? props.width : "30px")};
    color: ${props => (props.color ? props.color : "var(--grey)")};
  }

  &:disabled {
    transition: 0.4s;
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover {
    svg {
      transition: 0.4s;
      color: "var(--key-color-2)";
    }
  }
`;

export const FrCheckboxIcon = styled.div`
  background: transparent;
  padding: none;
  margin: none;
  outline: none;
  border: 2px solid transparent;
  box-shadow: 0px 0px 0px 2px transparent;
  transition: 0.4s;
  border-radius: 5px;

  svg {
    transition: 0.4s;
    width: ${props => (props.width ? props.width : "30px")};
    color: ${props => (props.color ? props.color : "var(--grey)")};
  }

  &:disabled {
    transition: 0.4s;
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover {
    svg {
      transition: 0.4s;
      color: "var(--key-color-2)";
    }
  }
`;

export const FrLabelledCheckbox = props => {
  return (
    <FrLabel>
      {props.children || props.text}
      <FrCheckboxIcon onClick={props.onClick}>{props.checked ? <CheckboxChecked /> : <Checkbox />}</FrCheckboxIcon>
    </FrLabel>
  );
};

export const FrIcon = styled.div`
  svg {
    transition: 0.4s;
    width: ${props => (props.width ? props.width : "30px")};
    color: ${props => (props.color ? props.color : "var(--grey-2)")};
  }
  padding: 10px;
`;

// Error
export const FrError = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
  color: var(--status-red);
  margin: 0 10px;
`;

export const FrFinePrint = styled.span`
  font-size: 12px;
  color: var(--grey-1);
`;

// Label
export const FrLabel = styled.label`
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  margin: ${props => (props.margin ? props.margin : "unset")};
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "bold")};
  color: ${props => (props.color ? props.color : "var(--grey)")};
  width: ${props => (props.width ? props.width : "100%")};
  padding: ${props => (props.padding ? props.padding : "")};
  opacity: ${props => (props.disabled === true ? ".4" : "1")};
  display: ${props => (props.display ? props.display : "flex")};
  white-space: nowrap;

  > svg {
    width: 14px;
    color: var(--grey-2);
    margin-left: 2px;
  }

  span {
    font-weight: 500;
  }

  div {
    font-weight: 600;
  }
`;

// Input
export const FrInput = styled.input`
  padding: ${props => (props.padding ? props.padding : "8px 20px 9px 20px")};
  width: ${props => (props.width ? props.width : "100%")};
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "500px")};
  background: ${props => (props.error ? "rgba(255, 92, 80, 0.15)" : props.background ? props.background : "white")};
  color: ${props => (props.color ? props.color : "var(--grey)")};
  margin: ${props => (props.margin ? props.margin : "4px 0 0")};
  box-shadow: 0px 0px 0px 2px transparent;
  border: 2px solid transparent;
  outline: none;
  transition: 0.4s;
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: textfield;

  ::placeholder {
    color: var(--grey-2);
    font-weight: 500;
  }

  &:disabled {
    transition: 0.4s;
    pointer-events: none;
    opacity: 0.4;
  }
  &:hover {
    transition: 0.4s;
    box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.25);
  }
  &:focus {
    transition: 0.4s;
    border: 2px solid var(--key-color-2);
    box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.2);
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

// Textarea
export const FrTextarea = styled.textarea`
  border: ${props => (props.$borderWarning ? "2px solid var(--status-red)" : "2px solid transparent")};
  width: ${props => (props.width ? props.width : "100%")};
  min-height: 58px;
  padding: 9px 20px;
  font-size: 14px;
  font-weight: 500px;
  field-sizing: content;
  outline: none;
  background: ${props => (props.error ? "rgba(255, 92, 80, 0.15)" : props.background ? props.background : "white")};
  color: var(--grey);
  transition:
    background-color 0.4s ease,
    box-shadow 0.4s ease;
  border-radius: 20px;
  margin: 4px 0 0;
  padding: 8px;
  resize: none;
  font-family: "Montserrat";

  ::placeholder {
    color: var(--grey-2);
    font-weight: 500;
  }

  &:disabled {
    transition:
      background-color 0.4s ease,
      box-shadow 0.4s ease;
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover {
    transition: box-shadow 0.4s ease;
    box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.25);
  }

  &:focus {
    transition:
      background-color 0.4s ease,
      box-shadow 0.4s ease;
    border: ${props => (props.$borderWarning ? "2px solid var(--status-red)" : "2px solid var(--key-color-2)")};
    box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const QuoteWrapper = styled.span`
  position: relative;

  &::before,
  &::after {
    position: absolute;
    font-size: 24px;
    color: var(--key-color-2);
    opacity: 0.5;
    line-height: 1;
    z-index: 1;
  }

  &::before {
    content: "“";
    top: -10px;
  }

  &::after {
    content: "”";
    top: -10px;
    right: 0px;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: rgb(102 102 102 / 70%);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation: ${smoothIn} 0.4s;
`;

// Button
export const FrButton = styled.button`
  width: ${props => (props.width ? props.width : "initial")};
  min-width: ${props => (props.minWidth ? props.minWidth : "unset")};
  white-space: ${props => (props.wrap ? "none" : "nowrap")};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: ${props => (props.margin ? props.margin : "0")};
  color: ${props => (props.color ? props.color : "white")};
  background: ${props => (props.background ? props.background : "var(--key-color-2-gradient)")};
  border: ${props => (props.border ? props.border : "none")};
  padding: ${props => (props.padding ? props.padding : "9px 36px")};
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "20px")};
  cursor: pointer;
  outline: none;
  font-family: "Montserrat";
  font-weight: bold;
  transition: 0.4s;
  opacity: 1;
  animation: ${props =>
    props.emphasis
      ? css`
          ${ButtonPulse} 1s infinite
        `
      : "none"};

  a {
    color: ${props => (props.color ? props.color : "white")};
  }

  svg {
    width: 20px;
    color: ${props => (props.color ? props.color : "white")};
    margin-right: 10px;
    animation: ${props =>
      props.loading !== undefined
        ? css`
            ${Spinning} 10s infinite
          `
        : "none"};
  }

  &:hover {
    transition: 0.4s;
    box-shadow: ${props => (props.boxShadow ? "0px 0px 5px" + props.boxShadow : "0px 0px 5px var(--key-color-2)")};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  @media (max-width: 1000px) {
    padding: 8px 15px;
  }
`;

export const FrIconButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  margin: 0 5px;
  svg {
    width: 25px;
    color: var(--key-color-2);
    transition: 0.4s;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover {
    svg {
      opacity: 1;
      transition: 0.4s;
    }
  }
`;

export const FrLinkButton = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: ${props => (props.margin ? props.margin : "0")};
  color: ${props => (props.color ? props.color : "white")};
  background: ${props => (props.background ? props.background : "var(--key-color-2-gradient)")};
  border: ${props => (props.border ? props.border : "none")};
  padding: ${props => (props.padding ? props.padding : "9px 36px")};
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "20px")};
  cursor: pointer;
  outline: none;
  font-family: "Montserrat";
  font-weight: bold;
  transition: 0.4s;
  opacity: 1;

  svg {
    width: 20px;
    color: ${props => (props.color ? props.color : "white")};
    margin-right: 10px;
    animation: ${props =>
      props.loading !== undefined
        ? css`
            ${Spinning} 10s infinite
          `
        : "none"};
  }

  &:hover {
    transition: 0.4s;
    box-shadow: ${props => (props.boxShadow ? "0px 0px 5px" + props.boxShadow : "0px 0px 5px var(--key-color-2)")};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const FrToggle = styled.input`
  :where([type="checkbox"][role="switch"]) {
    flex: 0 0 auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    font-size: inherit;
    width: 48px;
    height: 28px;
    box-sizing: content-box;
    border: 1px solid;
    border-radius: 20px;
    vertical-align: text-bottom;
    margin: auto;
    color: var(--grey-1);
    background: var(--grey-2);
  }

  // The round toggle
  :where([type="checkbox"][role="switch"])::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translate(0, -50%);
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    // border: 1px solid;
    border-radius: 50%;
    background: var(--grey-5);
  }

  :where([type="checkbox"][role="switch"]):checked {
    color: none;
    background: var(--key-color-2);
  }

  :where([type="checkbox"][role="switch"]):checked::before {
    left: 22px;
    background: white;
  }

  :where([type="checkbox"][role="switch"]):disabled {
    background: var(--grey-4);
    color: var(--grey-3);
  }
`;

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  width: calc(100% - 88px);
  bottom: 0;
  right: 0;
  position: fixed;
  background: white;
  z-index: 10;
  padding: 10px 20px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  animation: ${FadeIn} 2s;

  background: ${props => (props.hasBackground ? `url(${maskGroupImg})` : "initial")};
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;

  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const ToolbarSeparator = styled.div`
  flex-grow: 10;
`;

export const FrButtonLink = styled.button`
  background: transparent;
  color: var(--key-color-2);
  font-weight: 600;
  text-decoration: underline;
  border: none;
  outline: none;
`;

export const ErrorMessage = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 20px;
  padding: 10px;
  margin: 10px 0;
  font-weight: bold;
  text-align: center;
`;

// Div
export const FrDiv = styled.div`
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "initial")};
  padding: ${props => (props.padding ? props.padding : "0")};
  margin: ${props => (props.margin ? props.margin : "0")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "initial")};
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : "row")};
  align-items: ${props => (props.alignItems ? props.alignItems : "center")};
  align-content: ${props => (props.alignContent ? props.alignContent : "center")};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : "center")};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : "auto")};
  background: ${props => (props.background ? props.background : "inital")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "20px")};
  border: ${props => (props.border ? props.border : "none")};
  flex: ${props => (props.flex ? props.flex : "unset")};
  box-shadow: ${props => (props.boxShadow ? props.boxShadow : "unset")};
`;

export const FrContainer = styled.div`
  max-width: 1100px;
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  position: relative;
  transition: 0.4s;
  padding: 20px 20px 60px 20px;
  margin: ${props => (props.margin ? props.margin : "0")};
  background: var(--background-color);

  @media (max-width: 768px) {
    padding: 20px 20px 60px 20px;
  }

  @media (max-width: 450px) {
    padding: 20px 5px 60px 5px;
  }
`;

// Img
export const FrImg = styled.img`
  width: ${props => (props.width ? props.width : "100%")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "0")};
  margin: ${props => (props.margin ? props.margin : "0")};
`;

export const FrH1 = styled.h1`
  width: 100%;
  margin: ${props => (props.margin ? props.margin : "0")};
  color: ${props => (props.color ? props.color : "var(--grey)")};
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const FrH2 = styled.h2`
  width: 100%;
  margin: ${props => (props.margin ? props.margin : "24px 0 12px 0")};
  color: ${props => (props.color ? props.color : "var(--grey)")};
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const InlineInputWrapper = styled.div`
  // width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  padding: ${props => (props.padding ? props.padding : "15px 10px")};

  p {
    font-weight: 300;
  }

  h4,
  label {
    font-weight: 300;
    font-size: 14px;
  }

  input {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-right: 4px;
  }

  input[type="radio"] {
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
`;

export const CheckBoxWrapperLabel = styled.label`
  // margin-left: 10px;
  font-size: 14px;
  margin-right: 6px;
`;

export const ButtonField = styled.div`
  display: flex;
  overflow: hidden;
  font-size: 12px;

  button {
    border-radius: 0px;
  }

  button:first-of-type {
    border-radius: 20px 0 0 20px;
  }

  button:last-of-type {
    border-radius: 0 20px 20px 0;
  }
`;

const Pre = styled.pre`
  max-width: 100%;
  max-height: 200px;
  overflow: auto;
  background: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const JsonDisplay = ({ data }) => <Pre>{JSON.stringify(data, null, 2)}</Pre>;

export const DisableWrapper = styled.div`
  position: relative;
  width: 100%;

  ${props =>
    props.disabled &&
    `
    &::after {
      content: '${props.errorMessage || ""}';
      font-weight: 700;
      font-size: 18px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(128, 128, 128, 0.2);
      z-index: 10;
      cursor: not-allowed;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--status-red);
    }
  `}
`;

export const CoverImage = ({ coverImage, alt = "Cover Image", width, size = "320px", style = {}, ...props }) => {
  const mergedStyle = {
    ...style,
  };

  return coverImage ? (
    <img src={getCoverImage(coverImage, size)} alt={alt} width={width} height="auto" style={mergedStyle} {...props} />
  ) : null;
};
