import { components } from "react-select";
import { CircleWithPlus } from "@styled-icons/entypo/CircleWithPlus";
import { getAvatarImage } from "../../constants/avatars";
import { Folder } from "styled-icons/entypo";
import { CoverImage } from "../../components/DesignSystem/style";

export const selectScenarioFilterLogic = (option, inputValue) => {
  const id = option?.data?.id?.toLowerCase();
  const displayName = option?.data?.settings?.displayName?.toLowerCase();
  const input = inputValue?.toLowerCase();

  return id?.includes(input) || id?.replace(/-/g, " ")?.includes(input) || displayName?.includes(input);
};

export const searchAllValuesFilterLogic = (option, inputValue) => {
  if (!inputValue) return true;
  return Object.values(option?.data || {}).find(value =>
    String(value).toLowerCase().includes(inputValue.toLowerCase())
  );
};

export const AvatarOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      {data.label === "Create Avatar" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            background: "linear-gradient(91.82deg,#765CF6 3.83%,#A694FF 98.41%)",
            padding: "10px 0",
          }}
        >
          <div style={{ color: "white", fontSize: "14px" }}>
            {data.label}
            <CircleWithPlus style={{ marginLeft: "10px", width: "15px", color: "white" }} />
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ borderRadius: "50%" }}
              src={getAvatarImage(data.id, 256)}
              alt={"avatar"}
              width="50px"
              height="auto"
            />
            <span>&nbsp;&nbsp;</span>
            <div style={{ color: "var(--grey-2)", fontSize: "14px", padding: "0 0 0 10px" }}>{data.name}</div>
          </div>
        </div>
      )}
    </components.Option>
  );
};

export const AvatarOptionSignIn = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <div>{data.displayName}</div>
        </div>
      </div>
    </components.Option>
  );
};

export const EnvironmentOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={data.image} alt={"flag"} width="50px" height="auto" />
        <span>&nbsp;&nbsp;</span>
        <div>
          <div>{data.name}</div>
        </div>
      </div>
    </components.Option>
  );
};

export const NamedOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div>{data.name}</div>
    </components.Option>
  );
};

export const IntentOption = props => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div>{data.id}</div>
    </components.Option>
  );
};

export const LanguageOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
        <div>{data.name}</div>
      </div>
    </components.Option>
  );
};

export const OrganizationOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div>
        <div style={{ fontWeight: 800 }}>{data.displayName}</div>
        <div style={{ fontSize: 12 }}>{data.id}</div>
      </div>
    </components.Option>
  );
};

export const mapToScenarioOption = scenario => ({
  id: scenario.id,
  settings: {
    displayName: scenario.settings?.displayName ?? "",
    coverImage: scenario.settings?.coverImage,
  },
  isFolder: scenario.isFolder,
});

export const ScenarioOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
        <CoverImage coverImage={data.settings.coverImage} width="25px" style={{ marginRight: "5px" }} />
        <div>
          <div style={{ fontWeight: 800, display: "flex", gap: "8px", alignItems: "center" }}>
            {data.isFolder && <Folder width={18} />}
            {data.settings?.displayName || data.id}
          </div>
          <div style={{ fontSize: 12 }}>{data.id}</div>
        </div>
      </div>
    </components.Option>
  );
};

export const SummaryOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>&nbsp;&nbsp;</span>
        <div>
          <div>{data.subtitle}</div>
        </div>
      </div>
    </components.Option>
  );
};

// export const TypeOfUserOption = props => {
//   const { data } = props;
//   return (
//     <components.Option {...props}>
//       <div style={{ display: "flex", alignItems: "center" }}>
//         <span>&nbsp;&nbsp;</span>
//         <div>
//           <div>{data.displayName}</div>
//         </div>
//       </div>
//     </components.Option>
//   );
// };

export const UserOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "flexStart", flexDirection: "column" }}>
        <div>{data.displayName}</div>
        <p
          style={{
            fontSize: "12px",
            color: "var(--grey-2)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {data.email}
        </p>
      </div>
    </components.Option>
  );
};

export const VoiceOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <nobr>{data.voiceName}</nobr>
        </div>
        {data.description && (
          <p
            style={{
              fontSize: "12px",
              color: "var(--grey-2)",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span>&nbsp;&nbsp;</span>
            {data.description} {data.genders}
          </p>
        )}
      </div>
    </components.Option>
  );
};

export const SessionsFromDateOption = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div>
        <div>{data.label}</div>
      </div>
    </components.Option>
  );
};
