import styled from "styled-components";
import { smoothIn } from "../../styles/keyframes";

export const DialogueEditorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  animation: ${smoothIn} 0.6s;
`;

// Settings
export const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  ${"" /* background: var(--grey-4); */}
  ${"" /* overflow: hidden; */}
  border-radius: 20px;
  position: relative;

  h5 {
    width: 100%;
    text-align: left;
    padding: 0 10px 5px;
  }

  select {
    font-weight: 500;
    padding: 9px 20px;
    background: var(--grey-5);
  }
`;

// NEW EXCHANGE
export const SaveButton = styled.button`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : "15px")};
  border: none;
  background: linear-gradient(269.98deg, #a491fd 0.01%, #7f65ff 99.98%);
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  transition: 0.4s;
  border-radius: 20px;
  margin: 1em 0;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const ExchangeCont = styled.div`
  margin: 5px;
  transition: 0.6s;
`;

export const RowWrapper = styled.div`
  animation: ${smoothIn} 0.6s;
  display: flex;
  flex-direction: ${props => (!props.question ? "row" : "row-reverse")};
  align-items: center;
  width: 100%;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "20px")};
  background: ${props => (props.background ? props.background : "none")};
  padding: ${props => (props.padding ? props.padding : "0")};

  /* @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  } */
`;

// Checkbox input
export const CheckboxInputContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0 10px;
  p {
    font-size: 12px;
    font-weight: bold;
  }

  input {
    margin-left: 10px;
    width: initial;
  }
`;

export const NewExchangeCharTitle = styled.h5`
  position: absolute;
  top: -22px;
  left: ${props => (props.bot === true ? "10px" : "initial")};
  right: ${props => (props.bot === true ? "initial" : "10px")};
  color: ${props => (props.color ? props.color : "var(--grey-1)")};
  font-size: 14px;
`;

// AUDIO INPUT
export const FileInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  transition: 0.4s;
  div {
    width: 100%;
    padding: 0 0 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: flex-start;

    label {
      width: 50%;
      margin: 0 2px 0 0;
      border-radius: 20px;
      background: var(--key-color-2);
      color: white;
      text-align: left;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
      font-size: 12px;
      padding: 2px 20px;

      svg {
        color: white;
        width: 30px;
        margin-left: 5px;
      }
    }

    button {
      width: 50%;
      height: 34px;
      margin: 0 0 0 2px;
      padding: 5px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 20px;
      border: 2px solid var(--key-color-2);
      background: transparent;
      color: var(--key-color-2);
    }
  }

  audio {
    width: 100%;
    outline: none;
    padding: 5px 0;
  }

  p {
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    div {
      flex-direction: column;
      label {
        width: 100%;
        margin: 2px;
      }
      button {
        width: 100%;
        margin: 2px;
      }
    }
  }
`;

// CHAR SETTINGS
export const SettingsColumn = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : "0")};
  flex-wrap: wrap;

  /* @media (max-width: 768px) {
    padding: 30px;
  } */
`;

export const SettingWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;

  label {
    padding-left: 10px;
  }
`;

export const FlagContainer = styled.div`
  margin: 0 0 0 15px;

  /* @media (max-width: 450px) {
    margin: 10px 0 0 10px;
  } */
`;

export const ImagePosContainer = styled.div`
  display: flex;
  align-items: ${props => (props.noImage ? "center" : "flex-start")};
  justify-content: center;
  align-content: center;
  width: ${props => (props.width ? props.width : "inital")};
  min-width: ${props => (props.minWidth ? props.minWidth : "inital")};
  min-height: ${props => (props.minHeight ? props.minHeight : "inital")};
  border: ${props => (props.noImage ? "2px dashed var(--grey-3)" : "none")};
  background: ${props => (props.noImage ? "var(--grey-5)" : props.background ? props.background : "transparent")};
  padding: ${props => (props.padding ? props.padding : "inital")};
  border-radius: 20px;
  overflow: hidden;

  h5 {
    color: var(--grey-2);
    text-align: center;
    padding: 0;
  }

  img {
    transition: 0.4s;
    width: 100%;
    border-radius: 20px;
    transition: 0.4s;
  }

  .rsis-container > div {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
`;

export const CharWrapper = styled.div`
  flex: 1 1 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 10px;

  h5 {
    border-radius: 20px;
    width: 100%;
    text-align: left;
  }
`;

export const SelectScenarioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  padding: 10px 0;
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: 0.6s;
  animation: ${smoothIn} 0.4s;
  font-size: 14px;
  flex-wrap: wrap;

  /* svg {
    margin: 0 10px;
    color: var(--grey);
    width: 40px;
  }

  h5 {
    font-size: 14px;
  }

  select {
    max-width: 450px;
    font-weight: 600;
  } */
`;

export const SelectScenarioWrapper = styled.div`
  /* flex-basis: 400px; */
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px 10px 30px;
  position: relative;

  /* input {
    min-width: 250px;
  }

  select {
    min-width: 300px;
    margin: 0 10px;
    font-weight: 500;
    padding: 9px 20px;
    font-size: 14px;
  } */
`;

export const SelectScenarioInfo = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 30px 5px 30px;
  font-size: 14px;

  h5 {
    margin-right: 10px;
    color: var(--grey);
    span {
      color: var(--grey-2);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    h5 {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const FlexCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  @media (max-width: 768px) {
    width: 100%;
    align-content: flex-start;
    justify-content: flex-start;
    button {
      margin-top: 10px;
    }
  }
`;

export const CopyScenarioId = styled.h5`
  position: absolute;
  bottom: -7px;
  left: 150px;
  color: var(--grey-1);
  font-size: 12px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 35px;
    left: 40px;
  }
`;

export const CopyScenarioBtn = styled.button`
  padding: 9px 20px;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  background: var(--key-color-2);
  color: white;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const ErrorMsg = styled.span`
  padding: 0 5px;
  font-size: 12px;
  font-weight: 600;
  color: var(--red);
  width: ${props => (props.width ? props.width : "")};
`;

export const Saved = styled.h4`
  background: var(--key-color-2);
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation: ${smoothIn} 0.6s;
  padding: 5px;
  svg {
    color: var(--green);
    width: 30px;
  }
`;

export const ScenarioInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 5px 10px;
  p:first-child {
    margin: 0 5px 0 0;
  }
  p {
    font-size: 14px;
    margin: 0 5px 0 0;
    font-weight: 600;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 1300px) {
    flex-wrap: wrap;
  }
`;

export const AutogeneratedText = styled.div`
  position: absolute;
  font-size: 10px;
  right: 0;
  color: var(--grey-2);
`;

export const ScoreTab = styled.div`
  position: absolute;
  background: white;
  color: ${props => (props.color ? props.color : "var(--grey-2)")};
  padding: 5px 10px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  font-size: 12px;
  border-radius: 20px;
  z-index: 2;
  left: 34px;
  bottom: -14px;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;

  svg {
    margin-right: 5px;
    color: ${props => (props.color ? props.color : "var(--grey-3)")};
    width: 15px;
  }
`;

export const SkippedAnswer = styled.p`
  width: 100%;
  text-align: center;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  color: var(--grey-2);
`;

// DIALOGUE TITLE
export const DialogueTitle = styled.h2`
  width: 100%;
  min-width: fit-content;
  margin: 24px 0 12px 0;
  font-size: 24px;
`;

export const DialogueSectionDescription = styled.p`
  margin: 0 0 12px;
  // color: var(--grey-1);
  font-size: 14px;
  line-height: 1.4;
  // font-weight: 600;

  a {
    color: var(--key-color-2);
  }
`;

// DIALOGUE CONTAINER
export const DialogueContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 0 20px 20px 20px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  /* z-index: 1; */
  padding: 20px 32px;
  position: relative;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 0 20px 20px;
  }
`;

// DIALOGUE MENU STYLING
export const DialogueTabsUL = styled.ul`
  display: flex;
  align-items: flex-end;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding: 0 15px;

  @media (max-width: 768px) {
    // min-width: 500px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 2px 0 0;
  font-weight: bold;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  box-shadow: ${props =>
    props.warning
      ? "0 0 5px var(--status-yellow)"
      : props.selected
        ? "0px -1px 7px rgba(0, 0, 0, 0.1)"
        : "0px 0px 7px rgba(0, 0, 0, 0.1)"};
  color: ${props => (props.selected ? "var(--key-color-2)" : props.warning ? "var(--status-yellow)" : "var(--grey-3)")};

  background: white;
  transition: 0.4s;
  padding: 8px 24px;
  border: 2px solid var(--grey-3);
  border-color: ${props => (props.selected ? "var(--key-color-2)" : "var(--grey-3)")};
  border-radius: 20px 20px 0 0;
  &:hover {
    color: var(--key-color-2);
    border-color: var(--key-color-2);
  }

  @media (max-width: 768px) {
    min-width: unset;
    font-size: 14px;
    padding: 8px 8px;
  }
`;

export const MobileHiddenListItem = styled(ListItem)`
  @media (max-width: 500px) {
    display: none;
  }
`;

// DESCRIPTION STYLING
export const DescriptionContainer = styled.div`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : "24px 0")};

  p {
    max-width: 900px;
    font-weight: 500;
    color: var(--grey);
    width: 100%;
    font-size: 14px;
    padding: 0 0 10px 0;
    line-height: 1.4;

    span {
      font-weight: bold;
    }
  }

  h5 {
    max-width: 900px;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px 0;
    line-height: 1.4;

    span {
      font-weight: 500;
    }
    a {
      font-weight: bold;
      font-size: 14px;
      font-weight: 600;
      color: var(--key-color-2);
    }
  }
`;

export const ExpandButtonContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  max-width: 650px;
  min-width: 300px;
  margin-bottom: 16px;
  margin: 0 5px;
`;

export const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  width: 50%;
  border: none;
  padding: 8px 10px 8px 20px;
  font-size: 12px;
  font-weight: 600;
  color: var(--grey-1);
  margin: ${props => (props.margin ? props.margin : "0")};
  background: ${props => (props.show ? "var(--grey-4)" : "var(--grey-5)")};

  svg {
    width: 25px;
    transition: 0.4s;
    color: var(--grey-1);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 5px;
      color: ${props => (props.bestAnswer ? "var(--status-yellow)" : "var(--grey-2)")};
    }
  }

  :disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

// MENU TABS

export const TabsMenuContainer = styled.div`
  width: 100%;
  height: 45px;
  position: relative;

  svg {
    position: absolute;
    left: 8px;
    top: 8px;
    height: 50%;
    width: auto !important;
    margin: 0 !important;
  }

  button {
    position: absolute;
    right: 8px;
    top: 0;
    border-radius: 20px;
  }
`;

export const VoiceEditorPopupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: column;
  padding: 20px 30px;
  min-width: 400px;
  min-height: 500px;
  max-height: 700px;
  overflow: auto;
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;

export const SlidePopUpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: column;
  padding: 20px 30px;
  min-width: 600px;
  min-height: 500px;
  width: 70%;
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;

export const SlidesContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow-x: scroll;

  // img {
  //   width: 100px;
  // }

  @media (max-width: 768px) {
    padding: 0;
    margin-left: 0;
    width: 100%;
  }
`;

export const SlideItem = styled.div`
  padding: 5px 5px 0;
  background: var(--grey-5);
  margin: 5px;
  min-width: 290px;
  min-height: 240px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: move;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    width: 100%;
    padding: 0 0 10px;
    h5 {
      color: var(--grey);
      font-size: 14px;
      width: initial;
      pointer-events: none;
    }
  }

  img {
    width: 260px;
    border-radius: 10px;
    pointer-events: none;
  }
`;

export const SlideItemBtn = styled.button`
  margin: 5px 0 0;
  padding: 5px;
  background: transparent;
  outline: none;
  border: none;

  svg {
    color: var(--grey-1);
    width: 20px;
    transition: 0.4s;

    &:hover {
      transition: 0.4s;
      color: ${props => props.color};
    }
  }
`;

export const SlideUpload = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 8px 25px;
  background: var(--key-color-2);
  transition: 0.4s;
  cursor: pointer;
  input[type="file"] {
    display: none;
  }

  svg {
    width: 25px;
    color: white;
    margin-right: 5px;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  &:hover {
    transition: 0.4s;
    box-shadow: ${props => (props.boxShadow ? "0px 0px 5px" + props.boxShadow : "0px 0px 5px var(--key-color-2)")};
  }
`;

export const OldScenarioMessage = styled.p`
  margin-top: 10px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 12px;
  color: red;
`;

export const ScenarioFolderLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;

  span {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const ScenarioFolderMessage = styled.p`
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  color: gray;
`;

// SECTION
export const SectionContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const SectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled.h3`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  font-weight: bold;
  // width: 8.25rem;
  box-shadow: ${props =>
    props.warning
      ? "0 0 5px var(--status-yellow)"
      : props.selected
        ? "0px -1px 7px rgba(0, 0, 0, 0.1)"
        : "0px 0px 7px rgba(0, 0, 0, 0.1)"};
  color: var(--key-color-2);
  background: white;
  padding: 8px 24px;
  border-radius: 20px 20px 0 0;
  @media (max-width: 768px) {
    min-width: unset;
    font-size: 16px;
    padding: 8px 16px;
  }
`;

export const ActionListContainer = styled.div`
  width: 100%;
  max-width: 600px;
`;

export const ActionContainer = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-left: ${props => (props.level > 0 ? `${props.level * 50}px` : "0px")};
  width: ${props => (props.level > 0 ? `calc(100% - ${props.level * 50}px)` : "100%")};
  background: ${props => (props.newlyAdded ? "rgba(62, 150, 56, 0.05)" : "transparent")};
  box-shadow: ${props => (props.newlyAdded ? "0 0 10px rgba(75, 181, 67, 0.3)" : "none")};
  transition: background-color 0.6s ease-out;
`;

export const ActionFormLineItem = styled.li`
  margin-bottom: 5px;
  // border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionTopBar = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
  min-height: 20px;
  overflow: clip;

  input {
    margin: 0;
    min-width: 200px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    background: var(--grey-5);
    display: block;
  }

  p {
    white-space: nowrap;
    cursor: ${props => (!props.viewMode ? "pointer" : "initial")};
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--grey-2);
    white-space: nowrap;
    svg {
      transition: 0.4s;
      width: 20px;
      color: var(--grey-3);
    }
    :hover {
      svg {
        transition: 0.4s;
        color: var(--key-color-2);
      }
    }
  }
  p:first-letter {
    text-transform: capitalize;
  }
`;

export const Label = styled.span`
  font-size: 0.8rem;
  color: var(--grey);
  font-weight: 600;
  margin-bottom: 4px;
`;

export const ActionInlineForm = styled.div`
  padding: 8px;
  font-size: 14px;

  textarea {
    display: inline-block;
    opacity: 1 !important;
    vertical-align: middle;
    position: relative;
    top: -3px;
    padding: 4px;
    min-height: 30px;
    width: unset;
  }

  span,
  em,
  strong {
    white-space: nowrap;
    margin-right: 4px;
  }
`;

export const ScrollableFormTemplateList = styled.ul`
  max-height: 200px; /* Adjust height to show 3 templates at a time */
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ccc;
  margin: 4px 4px 20px 4px;
  padding: 10px;
  background: white;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }
`;

export const TemplateItem = styled.li`
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px; /* Increase margin for better separation */
  transition:
    background 0.3s,
    box-shadow 0.3s; /* Smooth transition for hover effects */

  &:hover {
    background: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow on hover */
  }

  &.non-interactive {
    pointer-events: none; /* Disable all pointer events */
    user-select: none; /* Disable text selection */
  }
`;
