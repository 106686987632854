import { joinPaths } from "../../helpFunctions/general";
import { getEnvironment } from "../../constants/defaults";

export const publicStoragePathPattern = /^(common|thumbs|scenarios|audio|organizations)/;

/**
 * Encodes strings for URI components but also parentheses, which is required if the URL will be used in a CSS
 * image url, which is typically enclosed in parentheses.
 * @param {string} str
 * @returns {string}
 */
export const encodeURIComponentExtra = str => {
  return str ? encodeURIComponent(str).replace("(", "%28").replace(")", "%29") : "";
};

export const getPublicUrl = filePath =>
  filePath?.match(publicStoragePathPattern)
    ? "https://firebasestorage.googleapis.com/v0/b/" +
      import.meta.env.VITE_STORAGE_BUCKET +
      "/o/" +
      encodeURIComponentExtra(filePath) +
      "?alt=media"
    : null;

export const getCoverImage = (coverImage, size = "320px") => {
  if (coverImage) {
    if (coverImage.startsWith("http")) {
      return coverImage;
    } else if (coverImage.indexOf("/") === -1) {
      return getThumbUrl(`common/media/${coverImage}`, size);
    } else {
      return getThumbUrl(coverImage, size);
    }
  }
  return null;
};

export const getEnvironmentBackground = environment => {
  if (!environment) return null;
  if (environment.startsWith("http")) {
    return environment;
  } else if (environment.indexOf("/") === -1) {
    return getEnvironment(environment)?.url360;
  } else {
    return getPublicUrl(environment);
  }
};

export const getSlideImage = (slideImage, path) => {
  if (slideImage) {
    if (slideImage.indexOf("/") === -1) {
      return getPublicUrl(joinPaths(path, slideImage));
    } else {
      return getPublicUrl(slideImage);
    }
  }
  return null;
};

export const getThumbUrl = (filePath, size = "320px") =>
  filePath?.match(publicStoragePathPattern)
    ? "https://firebasestorage.googleapis.com/v0/b/" +
      import.meta.env.VITE_STORAGE_BUCKET +
      "/o/" +
      encodeURIComponentExtra(filePath + `.resized.${size}.webp`) +
      "?alt=media"
    : null;

export const getSignedOrPublicUrl = eventObj => eventObj.url || getPublicUrl(eventObj.audio) || null;

export const getStorageUrl = filePath =>
  "https://storage.cloud.google.com/" + import.meta.env.VITE_STORAGE_BUCKET + filePath;

export const getStorageConsoleUrl = filePath =>
  "https://console.cloud.google.com/storage/browser/" + import.meta.env.VITE_STORAGE_BUCKET + filePath;

const rtdbId = import.meta.env.VITE_REALTIME_DB.split(".")[0].replace("https://", "");

export const getRTDBConsoleUrl = path =>
  "https://console.firebase.google.com/u/0/project/" +
  import.meta.env.VITE_PROJECT_ID +
  "/database/" +
  rtdbId +
  "/data/" +
  encodeURIComponent(path);

// Example input string: 2024-09-13T09:57:13.906065Z
// Example output string: 2024-09-13T09:59:13.906065Z
const dateWithOffset = (isoDateString, offsetMinutes) => {
  // Parse the ISO date string to a Date object
  const date = new Date(isoDateString);

  // Apply the offset in minutes
  date.setMinutes(date.getMinutes() + offsetMinutes);

  // Convert the updated Date object back to an ISO date string
  return date.toISOString();
};

export const getGoogleLogUrl = (sessionId, created) =>
  `https://console.cloud.google.com/logs/query;query=labels.session_id%3D%22${sessionId}%22;startTime=${dateWithOffset(created, -10)};endTime=${dateWithOffset(created, 90)}?project=${import.meta.env.VITE_PROJECT_ID}`;
