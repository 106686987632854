import React, { useState, useEffect, useCallback, useRef } from "react";
import { isString } from "lodash";
import { getThumbUrl } from "../Firebase/storage";
import { environmentToBackground } from "../../constants/defaults";
import { getRPMAvatarPng } from "../../constants/avatars";
import { joinPaths, lastPathSegment } from "../../helpFunctions/general";
import {
  FileLibraryContainer,
  SourceSection,
  SourceHeader,
  FilesGrid,
  FileItem,
  FileThumb,
  FileName,
  LoadingIndicator,
  FilesContainer,
  DialogContainer,
  SelectedFilesBox,
  SelectedFileItem,
  DialogContent,
  DialogHeader,
  DialogTitle,
  CloseButton,
  DialogFooter,
  CancelButton,
  SaveButton,
  EmptySelectionMessage,
  UploadButton,
  HiddenFileInput,
} from "./style";

/**
 * FileLibrary assumes files are objects that has at least an ID and a thumbnail. In earlier code
 * we only saved the filename, assuming it had a certain path in the storage. This function
 * should be used to convert the filename to a file object that the FileLibrary can understand.
 */
export const filenameToFileLibraryObjects = (filenames, path, size) => {
  if (!filenames) return [];
  if (isString(filenames)) filenames = [filenames];
  return filenames.map(filename => {
    if (filename.startsWith("http")) {
      // A full http path doesn't need to be converted at all
      return {
        id: filename,
        name: lastPathSegment(filename),
        thumbnail: filename,
      };
    } else if (filename.indexOf("/") > -1) {
      // A filename that includes a path component is assumed to reference an existing storage location
      return {
        id: filename,
        name: lastPathSegment(filename),
        thumbnail: getThumbUrl(filename, size),
      };
    } else if (environmentToBackground[filename]) {
      // A filename that matches a supported environment name is assumed to be a built-in environment
      return {
        id: environmentToBackground[filename],
        name: lastPathSegment(filename),
        thumbnail: getThumbUrl(environmentToBackground[filename], size),
      };
    } else if (path) {
      // A filename that is just a name is assumed to use the provided path
      return {
        id: joinPaths(path, filename),
        name: lastPathSegment(filename),
        thumbnail: getThumbUrl(joinPaths(path, filename), size),
      };
    } else {
      return [];
    }
  });
};

export const FileLibrary = ({
  sources = [],
  multiple = false,
  onChange = () => {},
  title = "Select Files",
  height = "auto",
  width = "fit-content",
  selectedFiles = [],
  disabled = false,
  embeddedMode = false, // New prop to control whether to show the selection box
  isOpen = false, // New prop to control dialog visibility externally
  onClose = () => {}, // New prop for handling dialog close events
}) => {
  const [sourceFiles, setSourceFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(isOpen);
  const [tempSelectedFiles, setTempSelectedFiles] = useState(selectedFiles);
  const [uploadingSources, setUploadingSources] = useState({});
  const [filesLoaded, setFilesLoaded] = useState(false);
  const fileInputRefs = useRef({});

  // Sync external isOpen state with internal isDialogOpen state
  useEffect(() => {
    setIsDialogOpen(isOpen);
  }, [isOpen]);

  // Memoize the loadFiles function to prevent re-creating it on every render
  const loadFiles = useCallback(async () => {
    // Don't load files if they're already loaded or loading
    if (filesLoaded || loading) return;

    console.log("Loading FileLibrary sources ...");
    setLoading(true);
    try {
      const filesObj = {};

      for (const source of sources) {
        try {
          const files = await source.getFiles();
          if (!Array.isArray(files)) {
            throw new Error("getFiles must return an array");
          }
          filesObj[source.name] = files || [];
        } catch (error) {
          filesObj[source.name] = [];
        }
      }

      setSourceFiles(filesObj);
      setFilesLoaded(true);
    } catch (error) {
      console.error("Error loading files:", error);
    } finally {
      setLoading(false);
    }
  }, [sources, loading, filesLoaded]);

  // Load files when dialog is opened or sources change while dialog is open
  useEffect(() => {
    if (isDialogOpen) {
      loadFiles();
    }
  }, [isDialogOpen, loadFiles]);

  // Reset filesLoaded state when sources change to force reloading
  useEffect(() => {
    setFilesLoaded(false);
  }, [sources]);

  // Update temp selected files when selectedFiles prop changes
  useEffect(() => {
    // Perform a deeper comparison to prevent unnecessary updates
    const currentIds = tempSelectedFiles
      .map(f => f.id)
      .sort()
      .join(",");
    const newIds = selectedFiles
      .map(f => f.id)
      .sort()
      .join(",");

    if (currentIds !== newIds) {
      setTempSelectedFiles(selectedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  const openFileDialog = () => {
    if (disabled) return;
    setIsDialogOpen(true);
  };

  const cancelAndClose = () => {
    setIsDialogOpen(false);
    // Reset temp selection
    setTempSelectedFiles(selectedFiles);
    // Notify parent component
    onClose();
  };

  const saveAndClose = () => {
    // Only call onChange if the selection has changed
    if (JSON.stringify(tempSelectedFiles) !== JSON.stringify(selectedFiles)) {
      onChange(tempSelectedFiles);
    }
    setIsDialogOpen(false);
    // Notify parent component
    onClose();
  };

  const handleFileClick = file => {
    if (multiple) {
      // If file is already selected, remove it
      if (tempSelectedFiles.some(f => f.id === file.id)) {
        setTempSelectedFiles(tempSelectedFiles.filter(f => f.id !== file.id));
      } else {
        // Otherwise add it to selection
        setTempSelectedFiles([...tempSelectedFiles, file]);
      }
    } else {
      if (file.id === tempSelectedFiles[0]?.id) {
        // If the file is already selected, deselect it
        setTempSelectedFiles([]);
      } else {
        setTempSelectedFiles([file]);
      }
    }
  };

  const isSelected = file => {
    return tempSelectedFiles.some(f => f.id === file.id);
  };

  const handleUploadClick = sourceId => {
    if (fileInputRefs.current[sourceId]) {
      fileInputRefs.current[sourceId].click();
    }
  };

  // Handle file selection from native file dialog
  const handleFileSelect = async (event, source) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // Set uploading state for this source
      setUploadingSources(prev => ({ ...prev, [source.name]: true }));

      // Read file data
      const fileData = await readFileAsArrayBuffer(file);

      // Call source's uploadFile function
      const uploadedFile = await source.uploadFile(file.name, fileData, { contentType: file.type });

      // Reload files to show the newly uploaded file
      setFilesLoaded(false);
      await loadFiles();

      // Clear the file input
      if (fileInputRefs.current[source.name]) {
        fileInputRefs.current[source.name].value = "";
      }

      // Find the newly uploaded file in the updated file list
      // If uploadFile returns the file object, use it directly
      if (uploadedFile && uploadedFile.id) {
        handleFileClick(uploadedFile);
      } else {
        // Otherwise try to find the file by name in the updated file list
        const updatedFiles = await source.getFiles();
        const newFile = updatedFiles.find(f => f.name === file.name || f.id.includes(file.name));

        if (newFile) {
          handleFileClick(newFile);
        }
      }
    } catch (error) {
      console.error(`Error uploading file to ${source.name}:`, error);
    } finally {
      // Reset uploading state
      setUploadingSources(prev => ({ ...prev, [source.name]: false }));
    }
  };

  const readFileAsArrayBuffer = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <>
      {!embeddedMode && (
        <SelectedFilesBox onClick={openFileDialog} width={width} height={height} disabled={disabled}>
          {selectedFiles.length > 0 ? (
            selectedFiles.map(file => (
              <SelectedFileItem key={file.id} single={selectedFiles.length === 1}>
                <FileThumb src={file.thumbnail} alt={file.name || file.id} />
                {/* <FileName>{file.name || file.id}</FileName> */}
              </SelectedFileItem>
            ))
          ) : (
            <EmptySelectionMessage>{disabled ? "No file selected" : "Click to select files"}</EmptySelectionMessage>
          )}
        </SelectedFilesBox>
      )}
      {isDialogOpen && (
        <DialogContainer>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{title}</DialogTitle>
              <CloseButton onClick={cancelAndClose}>×</CloseButton>
            </DialogHeader>

            <FileLibraryContainer height="100%" width="100%" style={{ flex: 1, overflow: "auto" }}>
              <FilesContainer>
                {loading ? (
                  <LoadingIndicator>Loading files...</LoadingIndicator>
                ) : (
                  <>
                    {sources.map(source => (
                      <SourceSection key={source.name}>
                        <SourceHeader>
                          {source.name}
                          {typeof source.uploadFile === "function" && (
                            <>
                              <UploadButton
                                onClick={() => handleUploadClick(source.name)}
                                disabled={uploadingSources[source.name]}
                              >
                                {uploadingSources[source.name] ? "Uploading..." : "Upload File"}
                              </UploadButton>
                              <HiddenFileInput
                                ref={el => (fileInputRefs.current[source.name] = el)}
                                onChange={e => handleFileSelect(e, source)}
                                accept="image/*"
                              />
                            </>
                          )}
                        </SourceHeader>
                        <FilesGrid>
                          {sourceFiles[source.name]?.map(file => (
                            <FileItem
                              key={file.id}
                              onClick={() => handleFileClick(file)}
                              selected={isSelected(file)}
                              title={file.name || file.id}
                            >
                              <FileThumb src={file.thumbnail} alt={file.name || file.id} />
                              <FileName>{file.name || file.id}</FileName>
                            </FileItem>
                          ))}
                          {sourceFiles[source.name]?.length === 0 && <div>No files available</div>}
                        </FilesGrid>
                      </SourceSection>
                    ))}
                    {sources.length === 0 && <div>No sources available</div>}
                  </>
                )}
              </FilesContainer>
            </FileLibraryContainer>

            <DialogFooter>
              <CancelButton onClick={cancelAndClose}>Cancel</CancelButton>
              <SaveButton onClick={saveAndClose}>Save</SaveButton>
            </DialogFooter>
          </DialogContent>
        </DialogContainer>
      )}
    </>
  );
};
