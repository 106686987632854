/*
This component supports rendering arbitrary settings from an object. It will use a JSON schema type object to 
understand type and titles, along with a translation object if desired. It also supports enabling/disabling a setting.
A disabled setting instead shows an inherited default setting (e.g from a parent context).

TODO:
Can we show where a default setting comes from, e.g. in the case of scenario settings we inherit both from defaults, user and org.
Can the component be broken into sections, or should that be done by rendering separate components?
How to handle that certain sections or settings are only for admins? E.g. a super admin looking at an organization setting should see more than a regular admin.
Maybe better to handle in separate instance.
*/
import { Edit } from "@styled-icons/boxicons-regular/Edit";
import { QuestionCircle } from "@styled-icons/fa-solid/QuestionCircle";
import { DeleteForever } from "@styled-icons/material/DeleteForever";
import { isNil, mapValues, merge } from "lodash";
import React, { useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import CustomSelect from "../../components/CustomSelect";
import { NamedOption } from "../../components/CustomSelect/customSelectOptions";
import { FrInput, FrLabel, FrTextarea } from "../../components/DesignSystem/style";
import { useFormField } from "../../components/FormProvider";
import { defaultTranslations, supportedKeys } from "../../constants/defaults";
import { HelpText, IconButton, ScrollableContainer, SettingsContainer, SettingsList, Toggle } from "./style";

/**
 * Merges a standard JSON schema with an optional extra schema, plus possibly default object.
 * It will merge in schemas so it's not possible to remove required fields from the standard schema.
 * @param {object} schema JSON schema object
 * @param {object} extraSchema optional JSON schema object
 * @param {object} defaultSettings
 * @returns {object} merged schema
 */
const mergeSchemas = (schema, extraSchema, defaultSettings) => {
  const mergedSchema = merge(schema, extraSchema);
  if (defaultSettings) {
    for (const key in mergedSchema) {
      if (key in defaultSettings) {
        mergedSchema[key].default = defaultSettings[key];
      }
    }
  }
  return mergedSchema;
};

/**
 * Gets a default value based on the newValue, defaultValue and schema.
 * @param {*} newValue
 * @param {*} defaultValue
 * @param {*} schema
 * @returns
 */
const getDefaultValue = (defaultValue, schema) => {
  let newValue = defaultValue ?? schema?.default;
  if (isNil(newValue)) {
    // Set a default value based on type
    if (schema.type === "boolean") {
      newValue = false;
    } else if (schema.type === "string") {
      if (schema.enum) {
        newValue = schema.enum[0];
      } else {
        newValue = "";
      }
    } else if (schema.type === "number" || schema.type === "integer") {
      newValue = 0;
    } else if (schema.type === "object") {
      newValue = {};
    }
  }
  return newValue;
};

/**
 *
 * @param {*} props
 * @param {string[]} props.settingKeys which settings to show in this Editor instance
 * @param {object} props.defaultSettings which default setting values to use for unset values. Can also be used to show where a setting comes from before.
 * @param {object} props.schema The schema to use for this layer of settings
 * @param {object} props.translations The translations to use for this layer of settings
 * @param {boolean} [props.showHelp=true] Whether to show help icons
 * @param {boolean} [props.compact=false] Whether to show a compact version of the editor
 * @param {boolean} [props.editorEditable=true] Whether to allow editing in the editor
 * @returns
 */
const SettingsEditor = ({
  settingKeys,
  fieldPath,
  defaultSettings,
  schema,
  translations = defaultTranslations.settings,
  extraSchema = {},
  extraTranslations = {},
  showHelp = true,
  compact = false,
  editorEditable = true,
  children,
}) => {
  schema = useMemo(() => mergeSchemas(schema, extraSchema, defaultSettings), [schema, extraSchema, defaultSettings]);
  translations = useMemo(() => merge(translations, extraTranslations), [translations, extraTranslations]);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredKeys = useMemo(() => {
    if (compact || !searchTerm) return settingKeys;

    const searchTermLower = searchTerm.toLowerCase();
    return settingKeys.filter(key => {
      const title = (translations[key]?.title || translations[key]?.name || key).toLowerCase();
      const description = (translations[key]?.description || "").toLowerCase();
      return (
        title.includes(searchTermLower) ||
        description.includes(searchTermLower) ||
        key.toLowerCase().includes(searchTermLower)
      );
    });
  }, [searchTerm, settingKeys, translations, compact]);

  return (
    <SettingsContainer>
      <HelpText>{children}</HelpText>
      {!compact && (
        <FrInput
          type="search"
          placeholder="Search settings..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{ marginBottom: "1rem" }}
        />
      )}
      <SettingsList compact={compact}>
        {filteredKeys.map(field => (
          <SettingsItem
            key={field}
            field={field}
            schema={schema}
            translations={translations}
            editorEditable={editorEditable}
            showHelp={showHelp}
            fieldPath={fieldPath}
          />
        ))}
      </SettingsList>
    </SettingsContainer>
  );
};

/**
 * Represents one individual setting. Keeping each as a separate component allows more efficient re-renders.
 */
const SettingsItem = ({ field, schema, translations, editorEditable, showHelp, fieldPath }) => {
  const path = fieldPath ? `${fieldPath}.${field}` : field;
  const { props, value: rawValue } = useFormField(path);
  if (!props) throw new Error("This component needs to be a descendant of a FormProvider");

  const s = schema[field] || {};
  const fieldTranslation = useMemo(() => translations[field] || {}, [translations, field]);
  const title = fieldTranslation.title || fieldTranslation.name || field;
  const description = fieldTranslation.description || "No description available";
  // If form value is nil, we represent it as disabled
  const enabled = !isNil(rawValue);
  const value = enabled ? rawValue : schema[field]?.default;
  const options = useMemo(
    () => s.enum?.map(e => ({ value: e, name: fieldTranslation[e]?.title || e })),
    [s.enum, fieldTranslation]
  );

  const toggleEnabled = () => {
    if (s.required) return; // Cannot toggle required settings
    const newEnabled = !enabled;
    let newValue;
    if (newEnabled) {
      newValue = getDefaultValue(null, s);
    } else {
      newValue = null;
    }
    // Notify the form that we have changed the value
    props.onChange(newValue);
  };

  return (
    <li key={field}>
      <FrLabel padding={"0 10px"} color={enabled ? "var(--grey)" : "var(--grey-2)"}>
        {title}
        {showHelp && (
          <>
            <QuestionCircle data-for={field} data-tip={description} />
            <ReactTooltip place="right" effect="solid" id={field} html={true} />
          </>
        )}
      </FrLabel>

      {s.type === "boolean" && (
        <Toggle
          type="checkbox"
          role="switch"
          checked={value === true}
          name={field}
          onChange={props.onChange}
          disabled={!enabled}
        />
      )}

      {s.type === "string" && s.enum && (
        <CustomSelect
          isSearchable={true}
          isMulti={false}
          value={value}
          onChange={props.onChange}
          options={options}
          option={NamedOption}
          placeholder={translations[field]?.[value]?.title || value || "Select ..."}
          disabled={!enabled}
          isDisabled={!enabled || !editorEditable}
          error={props.error}
        />
      )}

      {s.type === "string" && !s.enum && s.maxLength > 100 && (
        <FrTextarea
          type="text"
          maxLength={s.maxLength}
          minRows={2}
          name={field}
          error={props.error}
          onChange={props.onChange}
          value={value ?? ""}
          background={"var(--grey-5)"}
          disabled={!enabled || !editorEditable}
        />
      )}

      {s.type === "string" && !s.enum && (!s.maxLength || s.maxLength <= 100) && (
        <FrInput
          background={"var(--grey-5)"}
          name={field}
          type="text"
          error={props.error}
          min={s.minimum}
          max={s.maximum}
          onChange={props.onChange}
          value={value ?? ""}
          disabled={!enabled || !editorEditable}
        />
      )}

      {(s.type === "number" || s.type === "integer") && (
        <FrInput
          background={"var(--grey-5)"}
          width={"150px"}
          name={field}
          error={props.error}
          type="number"
          min={s.minimum}
          max={s.maximum}
          step={s.type === "integer" ? 1 : 0.1}
          onChange={props.onChange}
          value={value ?? 0}
          disabled={!enabled || !editorEditable}
        />
      )}

      {s.type === "object" && supportedKeys[field] && (
        <ScrollableContainer>
          <SettingsEditor
            settingKeys={Object.keys(supportedKeys[field] || {})}
            schema={mapValues(supportedKeys[field], v => ({ ...v, type: "boolean" }))}
            translations={field === "availableRatings" ? defaultTranslations.evaluation : supportedKeys[field]}
            showHelp={false}
            compact={true}
            editorEditable={enabled}
            fieldPath={path}
          />
        </ScrollableContainer>
      )}

      {/* TODO we don't have a fallback case for unknown types */}

      {!s.required && editorEditable && (
        <IconButton onClick={toggleEnabled}>{enabled ? <DeleteForever /> : <Edit />}</IconButton>
      )}
    </li>
  );
};

export default SettingsEditor;
